import React, { useContext, useEffect, useRef } from 'react'
import globalStyles from '../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import Marquee from "react-fast-marquee";
import { Typography } from '@mui/material';
import { DashboardContext, DashboardDetailPayload } from '../../Context/DashboardDetailContext';

const StocksMarquee = () => {

    const {
        stockMarqueDetails
    } = useContext(DashboardContext) as DashboardDetailPayload

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scriptId = "tradingview-ticker-tape-script";
        const existingScript = document.getElementById(scriptId);

        if (existingScript) {
            existingScript.remove();
        }

        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;

        script.innerHTML = JSON.stringify({
            symbols: [
                { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
                { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
                { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
                { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
                { proName: "BITSTAMP:ETHUSD", title: "Ethereum" }
            ],
            showSymbolLogo: true,
            isTransparent: true, // Background color is solid
            displayMode: "regular", // Regular ticker mode
            colorTheme: "dark", // Dark theme
            locale: "en" // English language
        });

        if (containerRef.current) {
            containerRef.current.innerHTML = ""; // Clear previous content
            containerRef.current.appendChild(script);
        }
    }, []);

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>

                    <div
                        className="tradingview-widget-container"
                        ref={containerRef}
                        style={{ width: "100%", background: "black" }}
                    >
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                    {/* <Marquee style={{ background: "black", padding: "5px 0px" }}>
                        {stockMarqueDetails.map((ele: any) => {
                            return (
                                <>
                                    <Grid container sx={{ alignItems: "center", gap: "2px", margin: "0px 10px" }}>
                                        <Grid sx={{ display: 'flex', margin: "0px 5px" }}>
                                            <img src={ele.img} style={{ width: '18px', height: 'auto' }} alt="" />
                                        </Grid>
                                        <Grid>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14}`}>
                                                {ele.stockText}
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            {ele.stockArrow}
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14}`}>
                                                {ele.stockPrice}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                    </Marquee> */}
                </Grid>
            </Grid>
        </>
    )
}

export default StocksMarquee
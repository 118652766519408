import React, { useContext } from 'react'
import styles from "../../../Styles/ComponentStyles/GlobalComponentStyles/SideNavigationPanel.module.scss"
import globalStyles from "../../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { Avatar, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GlobalUserContext, GlobalUserContextPayload } from '../../../Context/GlobalUserDetailContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import profile from '../../../Assets/General/profile.png'


const ProfileMenu = (props: any) => {

    const {
        drawerOpen,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const {
        userName,
        userEmail,
        anchorEl,
        handleClose,
        setAnchorEl,
        open,
        mobileOpen,
        anchorElMob,
        openMob,
        setAnchorElMob
    } = props
    const navigate = useNavigate()

    return (
        <>
            <Grid size={{ xs: 12 }} sx={{ padding: drawerOpen ? '0px 10px' : '0px 5px' }}>
                {mobileOpen ?
                    (<>
                        <Grid className={`${globalStyles.sideNavbarProfileItem}`}>
                            <Grid container className={`${globalStyles.sideNavProfileContainer}`}>
                                <Grid size={{ xs: 2 }} className={`${globalStyles.sideNavImg}`}>
                                    <Avatar
                                        src={profile}
                                        sx={{
                                            width: "30px",
                                            height: "30px"
                                        }}
                                    />
                                </Grid>
                                <Grid size={{ xs: "grow" }} className={`${globalStyles.sideNavAdminItem}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}>
                                                {userName ? userName : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}>
                                                {userEmail ? userEmail : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid size={{ xs: "auto" }} className={`${globalStyles.profileArrowItem}`}>
                                    <IconButton sx={{ padding: "0px" }} onClick={(event: any) => { setAnchorElMob(event.currentTarget); }} >
                                        <ArrowForwardIosIcon sx={{ fontSize: "18px", color: 'black' }} />
                                    </IconButton>
                                </Grid>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElMob}
                                    open={openMob}
                                    onClose={() => { setAnchorElMob(null); }}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            marginLeft: "15px",
                                            marginTop: "5px",
                                        }
                                    }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem
                                        onClick={(event: any) => {
                                            navigate('/profile');
                                            handleClose()
                                        }}
                                    >
                                        Profile
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleClose() }}>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </>) :
                    (<>
                        <Grid className={`${globalStyles.sideNavbarProfileItem}`}>
                            <Grid container className={`${globalStyles.sideNavProfileContainer}`}>
                                <Grid size={{ xs: drawerOpen ? 2 : 7.5 }} className={`${globalStyles.sideNavImg}`}>
                                    <Avatar
                                        src={profile}
                                        sx={{
                                            width: drawerOpen ? "100%" : "30px",
                                            height: drawerOpen ? "100%" : "30px"
                                        }}
                                    />
                                </Grid>

                                {!drawerOpen ? (<></>) : (<>
                                    <Grid size={{ xs: 7.5 }} className={`${globalStyles.sideNavAdminItem}`}>
                                        <Grid container>
                                            <Grid size={{ xs: 12 }}>
                                                <Typography
                                                    component={'span'}
                                                    className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}
                                                >
                                                    {userName ? userName : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 12 }}>
                                                <Typography
                                                    component={'span'}
                                                    className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}
                                                >
                                                    {userEmail ? userEmail : ""}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>)
                                }

                                <Grid size={{ xs: drawerOpen ? 2 : 3.5 }} className={`${globalStyles.profileArrowItem}`}>
                                    <IconButton sx={{ padding: "0px" }} onClick={(event: any) => { setAnchorEl(event.currentTarget); }} >
                                        <ArrowForwardIosIcon sx={{ fontSize: "18px", color: 'black' }} />
                                    </IconButton>
                                </Grid>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            marginLeft: "15px",
                                            marginTop: "5px",
                                            width: "220px"
                                        }
                                    }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    {!drawerOpen &&
                                        <Grid container sx={{ padding: "0px 10px" }}>
                                            <Grid size={{ xs: 12 }}>
                                                <Grid container className={`${globalStyles.sideNavProfileContainer}`}>
                                                    <Grid size={{ xs: 2 }} className={`${globalStyles.sideNavImg}`}>
                                                        <Avatar
                                                            src={profile}
                                                            sx={{
                                                                width: "30px",
                                                                height: "30px"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ xs: 9.8 }} className={`${globalStyles.sideNavAdminItem}`}>
                                                        <Grid container>
                                                            <Grid size={{ xs: 12 }}>
                                                                <Typography
                                                                    component={'span'}
                                                                    className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}
                                                                >
                                                                    {userName ? userName : ""}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid size={{ xs: 12 }}>
                                                                <Typography
                                                                    component={'span'}
                                                                    className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}
                                                                >
                                                                    {userEmail ? userEmail : ""}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                                <Divider sx={{ borderColor: "#D1D1D1" }} />
                                            </Grid>
                                        </Grid>
                                    }
                                    <MenuItem
                                        onClick={(event: any) => {
                                            navigate('/profile');
                                            handleClose()
                                        }}
                                    >
                                        Profile
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleClose() }}>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </>)
                }
            </Grid>
        </>
    )
}

export default ProfileMenu
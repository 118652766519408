import React, { ElementType, ReactNode } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Chip, SxProps, SelectProps, SelectChangeEvent } from '@mui/material';
import styles from './GlobalSelect.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface SelectOption {
    label: string;
    value: string | number;
}

interface GlobalSelectProps {
    options?: SelectOption[];
    value?: any;
    onChange?: (event: SelectChangeEvent<any>) => void;
    label?: string;
    placeholder?: string;
    sx?: SxProps;
    size?: 'small' | 'medium';
    className?: string;
    multiple?: boolean;
    fullWidth?: boolean;
    renderValue?: (selected: any) => ReactNode;
    error?: boolean;
    helperText?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
    IconComponent?: ElementType;
}

const GlobalSelect = (props: GlobalSelectProps) => {
    const {
        options,
        value,
        onChange,
        label,
        placeholder,
        sx,
        size,
        className,
        multiple,
        fullWidth,
        renderValue,
        error,
        helperText,
        onBlur,
        IconComponent = KeyboardArrowDownIcon,
        ...selectProps
    } = props;

    return (
        <FormControl fullWidth={fullWidth} error={error} sx={sx}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                multiple={multiple}
                value={value}
                onChange={onChange}
                renderValue={renderValue}
                size={size}
                IconComponent={(props) => (
                    <KeyboardArrowDownIcon {...props} sx={{ color: 'white' }} />
                )}
                className={`${className ? className : styles.selectStyles}`}

                displayEmpty
                {...selectProps}
            >
                {options?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <span>{helperText}</span>}
        </FormControl>
    );
};

export default GlobalSelect;

import React, { useState } from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/PortfolioDialog.module.scss'
import dashboardStyles from '../../Styles/ComponentStyles/MainPages/dashboard.module.scss'
import globalStyles from '../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import GlobalAutocomplate from '../../Utils/Layout/GlobalAutocomplate/GlobalAutoComplate';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';



interface PortfolioRow {
    symbol: string;
    quantity: number;
    purchasePrice: number;
    purchaseDate: string | null;
    sellPrice: number | null;
    sellDate: string | null;
}


const PortfolioDialog = () => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const handleSelectionChange = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedOptions(newValue || []);  // Ensure newValue is an array
    };

    const options = [
        { label: 'ADMS', value: 'adms' },
        { label: 'FB', value: 'fb' },
        { label: 'AAPL', value: 'aapl' },
        { label: 'GOOGL', value: 'googl' },
        { label: 'AMZN', value: 'amzn' },
    ];

    const [rows, setRows] = useState<PortfolioRow[]>([
        {
            symbol: 'ADMS',
            quantity: 100,
            purchasePrice: 100,
            purchaseDate: '2024-08-01',
            sellPrice: null,
            sellDate: null,
        },
        {
            symbol: 'FB',
            quantity: 500,
            purchasePrice: 500,
            purchaseDate: '2024-07-05',
            sellPrice: null,
            sellDate: null,
        },
    ]);

    const handleDateChange = (
        index: number,
        field: 'purchaseDate' | 'sellDate',
        value: string | null
    ) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} className={`${styles.portfolioFieldWrapper}`}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} className={`${styles.portfolioMainItem}`}>
                                    <Grid container sx={{ justifyContent: 'space-between', rowGap: "15px" }}>
                                        <Grid size={{ xl: 3.5, lg: 3.5, md: 3.5, sm: 3.8, xs: 12 }} className={`${styles.portfolioFieldlabelItem}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} className={`${styles.portfolioFieldlabelItem}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        Porfolio Name
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }}>
                                                    <GlobalTextfield
                                                        placeholder='Enter Portfolio Name'
                                                        fullWidth
                                                        size='medium'
                                                        autoComplete="off"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid size={{ xl: 3.5, lg: 3.5, md: 3.5, sm: 3.8, xs: 12 }}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} className={`${styles.portfolioFieldlabelItem}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        Template
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }}>
                                                    <GlobalAutocomplate
                                                        fullWidth={true}
                                                        size='small'
                                                        value={null}
                                                        options={[
                                                            {
                                                                label: "Test",
                                                                value: 1
                                                            },
                                                            {
                                                                label: "Test2",
                                                                value: 2
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid size={{ xl: 3.5, lg: 3.5, md: 3.5, sm: 3.8, xs: 12 }}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} className={`${styles.portfolioFieldlabelItem}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        Template
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }}>
                                                    <GlobalAutocomplate
                                                        fullWidth={true}
                                                        size='small'
                                                        value={null}
                                                        options={[
                                                            {
                                                                label: "Test",
                                                                value: 1
                                                            },
                                                            {
                                                                label: "Test2",
                                                                value: 2
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}>
                                                Assets (Press ctrl key to select multiple asset)
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            <Grid container sx={{ justifyContent: 'space-between', rowGap: "15px" }}>
                                                <Grid size={{ xl: 9.3, lg: 9.3, md: 9.3, sm: 12, xs: 12 }}>
                                                    <GlobalAutocomplate
                                                        placeholder="Select multiple assets"
                                                        options={options}
                                                        value={selectedOptions}
                                                        onChange={handleSelectionChange}
                                                        size="small"
                                                        fullWidth
                                                        multiple
                                                    />
                                                </Grid>
                                                <Grid size={{ xl: 2.5, lg: 2.5, md: 2.5, sm: "auto", xs: "auto" }}>
                                                    <GlobalButton
                                                        fullWidth
                                                        value='Add Portfolio'
                                                        className={`${styles.portfolioBtn}`}
                                                        size='large'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xs: 12 }} sx={{ margin: '10px 0px' }}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ margin: '10px 0px 0px' }}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}>
                                                Portfolio Structure
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TableContainer>
                                                    <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                                        <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableHeadCell}  ${globalStyles.gblLeftRadius}`}
                                                                    sx={{ fontSize: "14px" }}
                                                                >
                                                                    SYMBOL
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableHeadCell}`}
                                                                    sx={{ fontSize: "14px" }}
                                                                >
                                                                    QUANTITY
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableHeadCell}`}
                                                                    sx={{ fontSize: "14px", minWidth: "125px" }}
                                                                >
                                                                    PURCHASE PRICE
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableHeadCell}`}
                                                                    sx={{ fontSize: "14px", minWidth: "125px" }}
                                                                >
                                                                    PURCHASE DATE
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableHeadCell}`}
                                                                    sx={{ fontSize: "14px", minWidth: "80px" }}
                                                                >
                                                                    SELL PRICE
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`}
                                                                    sx={{ fontSize: "14px", minWidth: "125px" }}
                                                                >
                                                                    SELL DATE
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows?.map((row, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    className={`${globalStyles.gblTableBodyRow}`}
                                                                >
                                                                    <TableCell
                                                                        className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                                    >
                                                                        {row.symbol}
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className={`${globalStyles.gblTableCellText}`}
                                                                    >
                                                                        {row.quantity}
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className={`${globalStyles.gblTableCellText}`}
                                                                    >
                                                                        {row.purchasePrice}
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className={`${globalStyles.gblTableCellText} ${globalStyles.gblTableDatepickerCell}`}
                                                                    >
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                className={`${globalStyles.gblTableDatepicker}`}
                                                                                slots={{
                                                                                    textField: (params) => <TextField {...params} className={`${styles.portfolioCustomDatepicker}`} />
                                                                                }}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className={`${globalStyles.gblTableCellText}`}
                                                                    >
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius} ${globalStyles.gblTableDatepickerCell}`}
                                                                    >
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                slots={{
                                                                                    textField: (params) => <TextField {...params} className={`${styles.portfolioCustomDatepicker}`} />
                                                                                }}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </LocalizationProvider>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} className={`${styles.btnItem}`}>
                            <Grid container className={`${styles.btnContainers}`}>
                                <Grid>
                                    <GlobalButton
                                        value='Submit'
                                        size='large'
                                        className={`${styles.submitBtn}`}
                                    />
                                </Grid>
                                <Grid>
                                    <GlobalButton
                                        value='Delete'
                                        size='large'
                                        className={`${styles.deleteBtn}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default PortfolioDialog
import React, { useContext, useEffect, useState } from 'react'
import styles from "../../../Styles/ComponentStyles/MainPages/Member.module.scss"
import globalStyles from "../../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { Outlet, useNavigate } from 'react-router-dom';
import GlobalTabs from '../../../Utils/Layout/GlobalTabs/GlobalTabs';
import { members } from '../../../Utils/Constant/Constant';
import { Button, InputAdornment, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import GlobalTextfield from '../../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import Icons from '../../../Utils/Layout/Icons/Icons';
import { MemberContext, MemberContextPayload } from '../../../Context/MemberDetailsContext';

const RecentlyRegisteredUser = () => {
    const {
        memberUserDetails,
        setMemberUserDetails
    } = useContext(MemberContext) as MemberContextPayload

    const [page, setPage] = useState(1);
    const rowsPerPage = 12;
    const paginatedData = memberUserDetails.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const totalPages = Math.ceil(memberUserDetails.length / rowsPerPage);

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === "ArrowRight") {
                setPage((prevPage) => Math.min(prevPage + 1, totalPages));
            } else if (event.key === "ArrowLeft") {
                setPage((prevPage) => Math.max(prevPage - 1, 1));
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [totalPages]);


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid size={{ sm: 6, xs: 12 }}>
                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                        Show entries
                                    </Typography>
                                </Grid>
                                <Grid size={{ sm: 6, xs: 12 }}>
                                    <Grid container className={`${styles.memberSearchbarContainer}`}>
                                        <Grid className={`${styles.memberSearchbarItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                stylingClassName={`${styles.memberSearchbar}`}
                                                size='small'
                                                autoComplete='off'
                                                placeholder="Search"
                                                // onChange={(e) => {
                                                //     handleSearch(e.target.value)
                                                // }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Icons iconName="searchIcon" iconColor="#b6b6b6" iconWidth="18px" iconHeight="18px" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 0px" }}>
                            <TableContainer>
                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                        <TableRow>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableExtraSmallWidth}`}
                                                sx={{ fontSize: "14px", borderTopLeftRadius: "5px" }}
                                            >
                                                ID
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableLargeWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                USER NAME
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                FIRST NAME
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                LAST NAME
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                CONTACT
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                AGENCY
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableLargeWidth}`}
                                                sx={{ fontSize: "14px", borderTopRightRadius: "5px" }}
                                            >
                                                EMAIL
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                className={`${globalStyles.gblTableBodyRow}`}
                                            >
                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.id}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.userName}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.firstName}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.lastName}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.contact}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.agency}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.email}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack sx={{ flexDirection: "row", justifyContent: 'flex-end', margin: "10px 0px 0px" }}>
                                <Pagination
                                    count={Math.ceil(memberUserDetails.length / rowsPerPage)} // Total number of pages
                                    page={page}
                                    siblingCount={0} // Number of siblings around the current page
                                    boundaryCount={1} // Number of pages at the start and end
                                    onChange={handlePageChange}
                                    sx={{ "& .MuiPaginationItem-ellipsis": { color: "white" } }}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                            sx={{
                                                "&.Mui-selected": {
                                                    backgroundColor: "#4A6086",
                                                    color: "white",
                                                    "&:hover": {
                                                        backgroundColor: "#4A6086",
                                                        color: "white",
                                                    },
                                                },
                                                backgroundColor: "#ffffff",
                                                borderRadius: "8px",
                                                color: "#000000",
                                                "&:hover": {
                                                    backgroundColor: "#ffffff",
                                                    color: "#000000",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RecentlyRegisteredUser
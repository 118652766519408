export const intialUserDetails = {
  accessToken: "",
  flagLoggedIn: null,
  user: {
    userId: null,
    userName: null,
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    agencyName: "",
    profileImage: "",
    userStatus: [
      {
        status: "",
        id: ""
      }
    ],
    flagSuperAdmin: false,
    flagAdmin: null,
  },
  apps: []
}

export const loginPayloadDetails = {
  email: "",
  password: "",
  flagRememberMe: false,
  reCaptchaToken: ""
}

export const UserRegistrationPayloadDetails = {
  username: "",
  email_address: "",
  password: "",
  confirm_password: "",
  first_name: "",
  last_name: "",
  agency_name: "",
  phone: "",
  address_line_1: "",
  address_line_2: "",
  city_name: "",
  zip_code: "",
  state_id: null,
  how_did_you_find_us: "",
  affiliation: [],
  recaptcha: "",
}

export const forgotPasswordPayloadDetails = {
  password: "",
  confirm_password: "",
  otp: ""
}

export const stateOptions = [
  { label: "AL", value: 1 },
  { label: "AK", value: 2 },
  { label: "AZ", value: 3 },
  { label: "AR", value: 4 },
  { label: "CA", value: 5 },
  { label: "CO", value: 6 },
  { label: "CT", value: 7 },
  { label: "DE", value: 8 },
  { label: "FL", value: 9 },
  { label: "GA", value: 10 },
  { label: "HI", value: 11 },
  { label: "ID", value: 12 },
  { label: "IL", value: 13 },
  { label: "IN", value: 14 },
  { label: "IA", value: 15 },
  { label: "KS", value: 16 },
  { label: "KY", value: 17 },
  { label: "LA", value: 18 },
  { label: "ME", value: 19 },
  { label: "MD", value: 20 },
  { label: "MA", value: 21 },
  { label: "MI", value: 22 },
  { label: "MN", value: 23 },
  { label: "MS", value: 24 },
  { label: "MO", value: 25 },
  { label: "MT", value: 26 },
  { label: "NE", value: 27 },
  { label: "NV", value: 28 },
  { label: "NH", value: 29 },
  { label: "NJ", value: 30 },
  { label: "NM", value: 31 },
  { label: "NY", value: 32 },
  { label: "NC", value: 33 },
  { label: "ND", value: 34 },
  { label: "OH", value: 35 },
  { label: "OK", value: 36 },
  { label: "OR", value: 37 },
  { label: "PA", value: 38 },
  { label: "RI", value: 39 },
  { label: "SC", value: 40 },
  { label: "SD", value: 41 },
  { label: "TN", value: 42 },
  { label: "TX", value: 43 },
  { label: "UT", value: 44 },
  { label: "VT", value: 45 },
  { label: "VA", value: 46 },
  { label: "WA", value: 47 },
  { label: "WV", value: 48 },
  { label: "WI", value: 49 },
  { label: "WY", value: 50 }
]

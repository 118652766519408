import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../../Styles/ComponentStyles/MainPages/Signin.module.scss'
import globalStyles from '../../Styles/global.module.scss'
import ReCAPTCHA from "react-google-recaptcha";


import Grid from '@mui/material/Grid2';
import { Box, Button, Checkbox, colors, FormControlLabel, IconButton, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import logo from "../../Assets/Logos/login-mac-logo.png"
import agent from '../../Services/api';
import { useNavigate } from 'react-router-dom';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from '../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';
import ellipsis from "../../Assets/General/Ellipse.png"
import { loginPayloadModel } from '../../models/axiosModels';
import ComplianceDocument from '../../Components/ComplianceDocument/ComplianceDocument';
import { DashboardContext, DashboardDetailPayload } from '../../Context/DashboardDetailContext';
import { useIsBelow1200 } from '../../Utils/Constant/Constant';

const Signin = () => {

    const videoUrl = "https://player.vimeo.com/video/138115423?title=0&byline=0&portrait=0&controls=1";

    const {
        setUserLoggedInFlag,
        signinDetails,
        setSigninDetails,
        setFlagLoader,
        setUserProfileDetails,
        userProfileDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const {
        dashboardDetails,
        setDashboardDetails
    } = useContext(DashboardContext) as DashboardDetailPayload


    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const isBelow1200 = useIsBelow1200();
    const isBelow352 = useMediaQuery('(max-width : 352px) and (min-width : 0px)')
    const [showPassword, setShowPassword] = useState(false);
    const [fieldError, setFieldError] = useState({
        email: false,
        password: false
    });


    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password: string) => {
        return password.length >= 8;
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const email = event.target.value;
        setSigninDetails({
            ...signinDetails,
            email: email
        });
        setFieldError({
            ...fieldError,
            email: !validateEmail(email)
        });
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const password = event.target.value;
        setSigninDetails({
            ...signinDetails,
            password: password
        });
        setFieldError({
            ...fieldError,
            password: !validatePassword(password)
        });
    };

    const signIn = async () => {

        const payload: loginPayloadModel = {
            email: signinDetails?.email,
            password: signinDetails?.password,
            flagRememberMe: signinDetails?.flagRememberMe,
            reCaptchaToken: signinDetails?.reCaptchaToken
        }
        const { email, password } = signinDetails;
        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);

        if (!isEmailValid || !isPasswordValid) {

            setFieldError({
                ...fieldError,
                email: !isEmailValid,
                password: !isPasswordValid // Set password error if invalid
            });
            return;
        }
        setFlagLoader(true)
        try {
            // Wait for the response from the API call
            const response = await agent.signInAPI.post(payload);
            // console.log("response", response);
            if (response?.status) {
                const signinData = response?.data
                setUserLoggedInFlag(true)
                setFlagLoader(false)
                setUserProfileDetails({
                    ...userProfileDetails,
                    flagLoggedIn: true,   // Set flagLoggedIn to true
                    user: response.data.user,  // Store user information from the response
                    accessToken: response.data.accessToken,  // Store the access token
                });
                if (signinData.apps) {
                    setDashboardDetails({ apps: signinData.apps });
                }
                navigate('/');
                showSnackbar('Logged in Successfully!', 'success');
                setFlagLoader(false)
            }
            else {
                const errorMessage = response?.error?.message || 'Something Went Wrong!';
                showSnackbar(errorMessage, 'error');
                setUserProfileDetails({
                    ...userProfileDetails,
                    flagLoggedIn: false
                });
                navigate('/login');
                setUserLoggedInFlag(false)
                setFlagLoader(false)
            }
        }
        catch (error) {
            // Handle any errors that might occur during the API call
            console.error("Error during sign-in:", error);
            const errorMessage = 'Something Went Wrong!';
            showSnackbar(errorMessage, 'error');
            setFlagLoader(false)
            navigate('/login');
        }
    }


    // useEffect(() => {
    //     const iframe = iframeRef.current;
    //     if (iframe) {
    //         // Use the onLoad event for React
    //         iframe.onload = () => {
    //             const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
    //             if (iframeDocument) {
    //                 // Add styles to the iframe
    //                 const styleElement = iframeDocument.createElement("style");
    //                 styleElement.innerHTML = `
    //         .player {
    //           border-radius: 30px !important;
    //           border: 1px solid white !important;
    //         }
    //       `;
    //                 iframeDocument.head.appendChild(styleElement);
    //             }
    //         };
    //     }
    // }, [videoUrl]);



    return (
        <>
            <Grid container sx={{ height: '100vh' }}>
                <Grid size={{ xs: 12 }} >
                    <Grid container className={`${isBelow1200 ? styles.signinBelowTabStyles : ""}`}>
                        <Grid size={{ xs: 12 }} >
                            <Grid container className={`${globalStyles.fullHeight} ${styles.signinMainContainer}`}>
                                <Grid size={{ xl: 5.8, lg: 5.8, md: 12, sm: 12, xs: 12 }} className={`${styles.signinLeftSection}`}>
                                    <Grid container sx={{ height: "100%", alignItems: "center", }}>
                                        {isBelow1200 &&
                                            <Grid size={{ xs: 11 }} sx={{ textAlign: 'center', margin: "0 auto 30px" }}>
                                                <img
                                                    src={logo}
                                                    alt="Mac Acrylic Logo"
                                                    className={styles.siginMacLogoBelowTab}
                                                />
                                            </Grid>
                                        }
                                        <Grid className={`${styles.signinElipsis}`}>
                                            <img src={ellipsis} alt="" />
                                        </Grid>
                                        <Grid className={`${styles.signinFormItem}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px 15px" }}>
                                                    <Typography
                                                        component={'span'}
                                                        className={`${globalStyles.globalTextV35} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                    >
                                                        Sign in
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 40px" }}>
                                                    <Typography
                                                        component={'span'}
                                                        className={`${globalStyles.globalTextV22} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC4B4B4B}`}
                                                        sx={{ fontWeight: "600" }}
                                                    >
                                                        You can use login credentials from BA MAC,
                                                        Retirement ACE or LifeBrain.io
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                                    <Grid container >
                                                        <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 5px" }}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV18} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                E-mail
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                size='small'
                                                                fullWidth
                                                                placeholder='Enter Email'
                                                                stylingClassName={`${styles.signinTextfield}`}
                                                                autoComplete='off'
                                                                error={fieldError.email}
                                                                helperText={fieldError.email ? "Please enter a valid email address" : ""}
                                                                value={signinDetails.email}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleEmailChange(event)}

                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px 5px" }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 5px" }}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV18} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Password
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                size='small'
                                                                autoComplete='off'
                                                                placeholder='Enter Password'
                                                                type={showPassword ? 'text' : 'password'}
                                                                stylingClassName={`${styles.signinTextfield}`}
                                                                error={fieldError.password}
                                                                helperText={fieldError.password ? "Password must be at least 8 characters long" : ""}
                                                                value={signinDetails.password}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handlePasswordChange(event)}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                            <IconButton
                                                                                edge="start"
                                                                                size='small'
                                                                                onClick={handleClickShowPassword}
                                                                                sx={{
                                                                                    margin: "0px 10px 0px 5px",
                                                                                }}
                                                                            >
                                                                                {showPassword ?
                                                                                    <VisibilityOff
                                                                                        sx={{ fontSize: "23px", color: "#718096" }}
                                                                                    /> :
                                                                                    <Visibility
                                                                                        sx={{ fontSize: "23px", color: "#718096" }}
                                                                                    />
                                                                                }
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container sx={{ alignItems: "center" }}>
                                                        <Grid size={{ xs: isBelow352 ? 12 : 6 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={signinDetails.flagRememberMe}
                                                                        onChange={(event) => {
                                                                            setSigninDetails({
                                                                                ...signinDetails,
                                                                                flagRememberMe: event.target.checked,
                                                                            });
                                                                        }}

                                                                    />
                                                                }
                                                                label="Remember Me"
                                                                className={styles.signinCheckboxLabel}
                                                            />
                                                        </Grid>
                                                        <Grid size={{ xs: isBelow352 ? 12 : 6 }} sx={{ textAlign: isBelow352 ? 'start' : 'end' }}>
                                                            <Typography
                                                                component='span'
                                                                className={styles.signinForgetPwtext}
                                                                sx={{ cursor: "pointer" }}
                                                                onClick={() => { navigate('/reset-password') }}
                                                            >
                                                                Forgot Password?
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px" }}>
                                                    <ReCAPTCHA
                                                        className={`${styles.gRecaptcha}`}
                                                        sitekey="6LfyVJ0UAAAAAJOnWbofCE79xHEH6Rhk4mwEX00q"
                                                        onChange={(token: string | null) => {
                                                            setSigninDetails({
                                                                ...signinDetails,
                                                                reCaptchaToken: token
                                                            });
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                                    <GlobalButton
                                                        fullWidth
                                                        value="Sign in"
                                                        size="large"
                                                        variant='contained'
                                                        className={`${styles.signInButton}`}
                                                        onClick={() => { signIn() }}
                                                    />
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                                                    <Typography component={'span'} className={`${styles.registerLink}`}>
                                                        Not a Member? <Link to={"/register"} className={`${styles.registerLinkText}`}>Register Here</Link>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid size={{ xl: 5.8, lg: 5.8, md: 0, sm: 0, xs: 0 }} className={`${styles.signinBgWrapper}`}>
                                    <Grid container>
                                        <Grid className={`${styles.signinElipsis}`}>
                                            <img src={ellipsis} alt="" />
                                        </Grid>
                                        <Grid size={{ xs: 10 }} className={`${styles.signinBgSubWrapper}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
                                                    <img src={logo} style={{ width: "auto", height: "50px" }} alt="" />
                                                </Grid>
                                                <Grid className={`${styles.signinIframeItem}`}>
                                                    <iframe
                                                        ref={iframeRef}
                                                        src={videoUrl}
                                                        frameBorder="0"
                                                        allow="autoplay; fullscreen"
                                                        allowFullScreen
                                                        title="Vimeo Video"
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid className={`${styles.signinIntroText}`}
                                                    sx={{ width: "500px" }}
                                                >
                                                    <Typography component={'span'} >
                                                        Introducing myAdvisorCloud.
                                                    </Typography>
                                                </Grid>
                                                <Grid className={`${styles.signinIntroSubText}`}
                                                    sx={{ width: "500px" }}
                                                >
                                                    <Typography component={'span'}>
                                                        The ONLY customized agent login portal in our industry offered by
                                                        a brokerage that understands what innovation really means.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xl: 12, lg: 12, md: 11.8, sm: 11.8, xs: 11.5 }} sx={{ margin: "0 auto" }}>
                            <ComplianceDocument policyBg="#FBFDFE" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Signin
import React from 'react'
import styles from "../../Styles/ComponentStyles/MainPages/RevenueApp.module.scss"
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import AppNavbar from '../../Components/AppNavbar/AppNavbar';
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import GlobalSelect from '../../Utils/Layout/GlobalSelect/GlobalSelect';
import Icons from '../../Utils/Layout/Icons/Icons';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import { genericRevenueAppData, revenueAppData } from '../../Utils/Constant/Constant';

const RevenueApp = () => {
    return (
        <>
            {/* <AppNavbar
                navbarHeadingName={"Revenue App"}
                children={
                    <Grid>
                        <GlobalButton
                            value="Generate PDF"
                            size='small'
                            className={`${styles.generatePDFBtn}`}
                            onClick={() => {
                            }}
                        />
                    </Grid>
                }
            /> */}
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px" }}>
                            <Grid container className={`${styles.revenueAppContainer}`}>
                                <Grid className={`${styles.revenueAppFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ md: 4, sm: 12, xs: 12 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Mode
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ md: 8, sm: 12, xs: 12 }}>
                                            <GlobalSelect
                                                size='small'
                                                fullWidth
                                                value={null}
                                                className={`${globalStyles.gblDropdownStyles}`}
                                                options={[
                                                    {
                                                        label: "Test",
                                                        value: "test"
                                                    },
                                                    {
                                                        label: "Test2",
                                                        value: "test2"
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${styles.revenueAppEmailFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ md: 4, sm: 12, xs: 12 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Test Email Address
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ md: 8, sm: 12, xs: 12 }} className={`${globalStyles.revenueEmailItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                value={""}
                                                size='small'
                                                placeholder='Enter Email Address'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${styles.revenueAppFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ md: 3, sm: 12, xs: 12 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Month
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ md: 8, sm: 12, xs: 12 }} >
                                            <GlobalSelect
                                                size='small'
                                                fullWidth
                                                value={null}
                                                className={`${globalStyles.gblDropdownStyles}`}
                                                options={[
                                                    {
                                                        label: "January",
                                                        value: "january"
                                                    },
                                                    {
                                                        label: "Fab",
                                                        value: "fab"
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${styles.revenueAppFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ md: 3, sm: 12, xs: 12 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Year
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ md: 8, sm: 12, xs: 12 }}>
                                            <GlobalSelect
                                                size='small'
                                                fullWidth
                                                value={null}
                                                className={`${globalStyles.gblDropdownStyles}`}
                                                options={[
                                                    {
                                                        label: "2019",
                                                        value: "2019"
                                                    },
                                                    {
                                                        label: "2020",
                                                        value: "2020"
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={false}
                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                sx={{ padding: "0px 8px" }}
                                            />
                                        }
                                        label="Remember Me"
                                        sx={{ color: "#ffffff" }}
                                    />
                                </Grid>

                                <Grid className={`${styles.groupBtnItem}`} size={{ xs: "grow" }} sx={{ textAlign: "end" }}>
                                    <GlobalButton
                                        value='Groups'
                                        size='medium'
                                        className={`${globalStyles.gblContainedBtn}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <TableContainer>
                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                        <TableRow>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px", borderTopLeftRadius: "5px" }}
                                            >
                                                USER NAME
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableLargeWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                EMAIL
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableSmallWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                REP CODE
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableSmallWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                INCLUDE?
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableLargeWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                GROUP
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                PAYOUT AMOUNT
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ACCOUNTS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ACCOUNTS IN ERROR
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                BILLABLE MV
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ADVISOR SPLIT ADJUSTMENT
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                EXCLUSIONS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                DISCOUNTS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell}  ${globalStyles.gblTableMediumWidth}`}
                                                sx={{ fontSize: "14px", borderTopRightRadius: "5px" }}
                                            >
                                                CASH SHORTAGES
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {revenueAppData?.map((row: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                className={`${globalStyles.gblTableBodyRow}`}
                                            >
                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.userName}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.email}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.repcode}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblFieldFitStyles}`}
                                                >
                                                    <GlobalSelect
                                                        options={genericRevenueAppData.include.map((option: any) => ({ label: option.groupName, value: option.id }))}
                                                        value={row.include}
                                                        fullWidth
                                                        size='small'
                                                        // onChange={(event: any) => { handleSelectChange(event) }}
                                                        className={`${globalStyles.gblTableDropdownFitField} ${globalStyles.gblTableFieldDropdownSize}`}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblFieldFitStyles}`}
                                                >
                                                    <GlobalSelect
                                                        options={genericRevenueAppData.include.map((option: any) => ({ label: option.groupName, value: option.id }))}
                                                        value={row.groupValue}
                                                        fullWidth
                                                        size='small'
                                                        // onChange={(event: any) => { handleSelectChange(event) }}
                                                        className={`${globalStyles.gblTableDropdownFitField} ${globalStyles.gblTableFieldDropdownSize}`}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.payoutAmount}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.accounts}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.accountInError}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.billableMv}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.splitAdjustment}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.exclusions}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.discounts}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                    contentEditable={true}
                                                    suppressContentEditableWarning
                                                >
                                                    {/* <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.cashShortages}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    /> */}
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        <TableRow
                                            className={`${styles.revenueTotalRow}`}
                                        >
                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                Total
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >

                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >

                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >

                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText}`}
                                            >
                                                $0.00
                                            </TableCell>

                                            <TableCell
                                                className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                            >
                                                $0.00
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RevenueApp
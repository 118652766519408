import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/CredentialDialog.module.scss'
import dialogStyles from "../../Styles/ComponentStyles/GlobalComponentStyles/AppClientMarketingDialog.module.scss"

import globalStyles from "../../Styles/global.module.scss"
import { IconButton, InputAdornment, Typography } from '@mui/material';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import agent from '../../Services/api';
import { useSnackbar } from '../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';



const CredentialsDialog = (props: any) => {

    const { getRedtailSSO, setCurrentAppUrl, setFlagCredentialDialog, } = props

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        username: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false)
    const { showSnackbar } = useSnackbar();

    // Handle field changes
    const handleChange = (field: string, value: string) => {
        setFormData({
            ...formData,
            [field]: value,
        });

        // Clear the error for the field when typing
        setErrors({
            ...errors,
            [field]: '',
        });
    };

    // Validate the form fields
    const validateFields = () => {
        let valid = true;
        const newErrors = { username: '', password: '' };

        if (!formData.username.trim()) {
            newErrors.username = 'Username is required.';
            valid = false;
        }

        if (!formData.password.trim()) {
            newErrors.password = 'Password is required.';
            valid = false;
        } else if (formData.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSave = async (action: string) => {

        let payload = {
            userName: formData.username,
            password: formData.password
        }


        if (validateFields()) {
            const response: any = await agent?.redtailCredentialAPI?.post(payload)
            console.log("response of redtailCredentialAPI", response);
            if (response?.data?.status) {
                console.log("response of cred", response);
                if (action == "Save") {
                    console.log("Credentials saved successfully.");
                    setFlagCredentialDialog(false);
                    showSnackbar("Credentials saved successfully!", "success");
                }
                else if (action == "SaveAndContinue") {
                    const userKey = response.data?.userKey;
                    if (userKey) {
                        showSnackbar("Credentials saved successfully!", "success");
                        setCurrentAppUrl(userKey);
                        getRedtailSSO(userKey);
                        setFlagCredentialDialog(false);
                    }
                    else {
                        console.log("else part", response);
                        const error = response?.error?.message ? response?.error?.message : "Something Went Wrong!"
                        showSnackbar(error, "error");
                    }
                }
            }
            else {
                console.log("response of cred false", response);
                const error = response?.response?.data?.error?.message ? response?.response?.data?.error?.message : "Something Went Wrong!"
                showSnackbar(error, "error");
            }
        }

    };


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }} sx={{ padding: "15px" }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                            <Grid container>
                                <Grid size={12} sx={{ margin: "0px 0px 8px" }}>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                    >
                                        Username
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <GlobalTextfield
                                        size='medium'
                                        fullWidth
                                        value={formData.username}
                                        stylingClassName={`${globalStyles.globalFieldStyles}`}
                                        onChange={(e: any) =>
                                            handleChange('username', e.target.value)
                                        }
                                        error={!!errors.username}
                                        helperText={errors.username}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                            <Grid container>
                                <Grid size={12} sx={{ margin: "0px 0px 8px" }}>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                    >
                                        Password
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <GlobalTextfield
                                        size='medium'
                                        fullWidth
                                        error={!!errors.password}
                                        stylingClassName={`${globalStyles.globalFieldStyles}`}
                                        helperText={errors.password}
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData.password}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                paddingRight: "0px !important"
                                            }
                                        }}
                                        onChange={(e: any) =>
                                            handleChange('password', e.target.value)
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                    <IconButton
                                                        edge="start"
                                                        size='small'
                                                        onClick={() => { setShowPassword((prev) => !prev); }}
                                                        sx={{
                                                            margin: "0px 10px 0px 5px",
                                                        }}
                                                    >
                                                        {showPassword ?
                                                            <VisibilityOff
                                                                sx={{ fontSize: "23px", color: "#718096" }}
                                                            /> :
                                                            <Visibility
                                                                sx={{ fontSize: "23px", color: "#718096" }}
                                                            />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px 20px" }}>
                            <Grid container sx={{ justifyContent: "space-between" }}>
                                <Grid size={{ xs: 3 }} className={`${styles.credDialogCancelBtnItem}`}>
                                    <GlobalButton
                                        value={'Cancel'}
                                        size='large'
                                        fullWidth
                                        className={dialogStyles.closeBtnStyle}
                                        onClick={() => { setFlagCredentialDialog(false); }}
                                    />
                                </Grid>
                                <Grid size={{ xs: 3 }} className={`${styles.credDialogSaveBtnItem}`}>
                                    <GlobalButton
                                        value={'Save'}
                                        size='large'
                                        fullWidth
                                        className={dialogStyles.globalSsoBtnStyles}
                                        onClick={() => {
                                            handleSave("Save")
                                        }}
                                    />
                                </Grid>
                                <Grid size={{ xs: 4.5 }} className={`${styles.credDialogSaveAndGoItem}`}>
                                    <GlobalButton
                                        value={'Save & Go to Site'}
                                        size='large'
                                        fullWidth
                                        className={styles.saveAndGoBtnStyles}
                                        onClick={() => { handleSave("SaveAndContinue") }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CredentialsDialog
import React from "react";
import { Dialog, Divider, IconButton, SxProps, Theme, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./GlobalDialog.module.scss"

// Define the interface for the component props
interface GlobalDialogProps {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    className?: string;
    open: boolean;
    onClose: () => void;
    sxDialog?: SxProps<Theme>;
    PaperProps?: object;
    view: React.ReactNode;
    backgroundColor?: string;
    dialogHead: string;
    flagHeadSection: boolean;
    child?: React.ReactNode;
}

const GlobalDialog: React.FC<GlobalDialogProps> = (props) => {

    const {
        maxWidth,
        className,
        open,
        onClose,
        sxDialog,
        PaperProps,
        view,
        backgroundColor = "#273348",
        dialogHead,
        flagHeadSection,
        child
    } = props

    return (
        <Dialog
            maxWidth={maxWidth}
            fullWidth={true}
            className={className}
            open={open}
            onClose={onClose}
            sx={sxDialog}
            PaperProps={{
                style: { backgroundColor },
                ...PaperProps
            }}
        >
            <Grid container sx={{ padding: "0px 20px 0px 20px" }}>
                {flagHeadSection &&
                    <Grid size={{ xs: 12 }} className={`${styles.gblHeaderDialogItem}`}>
                        <Grid container>
                            <Grid size={{ xs: 12 }}>
                                <Grid container sx={{ alignItems: "center", margin: "0px 0px 10px", justifyContent: 'space-between' }}>
                                    <Grid size={{ xs: child ? 6 : "grow" }}>
                                        <Typography
                                            component={'span'}
                                            sx={{ color: '#ffffff', fontSize: "22px", fontWeight: '500' }}
                                        >
                                            {dialogHead}
                                        </Typography>
                                    </Grid>
                                    {child &&
                                        <Grid size={{ xs: 5 }}>
                                            <Grid container sx={{ justifyContent: "end" }}>
                                                {child}
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid size={{ xs: "auto" }} sx={{ textAlign: 'end' }}>
                                        <IconButton onClick={onClose} sx={{ padding: "0px 0px 0px 5px" }}>
                                            <CloseIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <Divider sx={{ borderColor: "#D9DFE6" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid size={{ xs: 12 }}>
                    {view}
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default GlobalDialog;

import React, { ReactNode, useEffect, useState } from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/Appnavbar.module.scss'
import globalStyles from '../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import { Divider, Typography } from '@mui/material'

interface navbarProps {
    navbarHeadingName?: string;
    showButtonFlag?: boolean
    children?: ReactNode;
    flagShowDivider?: boolean
    isBelow1200?: any
}

const AppNavbar = (props: navbarProps) => {

    const { navbarHeadingName, showButtonFlag, children, flagShowDivider, isBelow1200 } = props
    const pathName = window.location.pathname.toLowerCase()

    const [headerName, setHeaderName] = useState<string>("")

    useEffect(() => {
        if (pathName == '/dashboard' || pathName == '/') {
            setHeaderName("Dashboard")
        }
        else if (pathName.includes('/dashboard/revenue')) {
            setHeaderName("Dashboard")
        }
        else if (pathName === '/dashboard/plan401k') {
            setHeaderName("Dashboard")
        }
        else if (pathName === '/dashboard/marketing-and-sales') {
            setHeaderName("Dashboard")
        }
        else if (pathName.includes('/member')) {
            setHeaderName("Member")
        }
        else if (pathName.includes('/member/user')) {
            setHeaderName("Member")
        }
        else if (pathName === '/plan401kfee') {
            setHeaderName("4O1 (k) Fee report")
        }
        else if (pathName === '/revenueapp') {
            setHeaderName("Revenue App")
        }
        else if (pathName === '/leaderboard') {
            setHeaderName("Leaderboard")
        }
        else if (pathName === '/leaderboard') {
            setHeaderName("Leaderboard")
        }
        else if (pathName.includes('/profile')) {
            setHeaderName("Profile")
        }
        else {
            setHeaderName("")
        }

    }, [pathName])


    return (
        <>
            <Grid sx={{ width: "100%" }} container className={`${isBelow1200 ? styles.navbarMainContainerForBelow1200 : styles.navbarMainContainer}`}>
                <Grid size={{ xs: 12 }} className={`${styles.navbarMainItem}`}>
                    <Grid container sx={{ gap: "15px" }}>
                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ sm: 6, xs: 12 }}>
                                    <Typography
                                        component={'span'}
                                        className={`${styles.navbarHeadText} ${globalStyles.gblOnelineEllipsis}`}
                                    >
                                        {headerName}
                                    </Typography>
                                </Grid>
                                <Grid size={{ sm: 6, xs: 0 }}>
                                    <Grid container className={`${styles.navChildContainer}`}>
                                        {children}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {flagShowDivider &&
                            <Grid size={{ xs: 12 }}>
                                <Divider sx={{ borderColor: '#FFFFFF' }} />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AppNavbar
import React from 'react'
import Grid from '@mui/material/Grid2';
import styles from '../../Styles/ComponentStyles/MainPages/dashboard.module.scss'
import globalStyles from '../../Styles/global.module.scss'

import pdf from '../../Assets/Dashboard/pdf.png'
import { Typography } from '@mui/material';
import { useIsBelow1200 } from '../../Utils/Constant/Constant';

interface pdfCardsProps {
    pdfMainName: string,
    pdfSubName: string
}


const PdfCards = (props: pdfCardsProps) => {

    const { pdfMainName, pdfSubName } = props
    const isBelow1200 = useIsBelow1200();

    return (
        <>
            <Grid container>
                {isBelow1200 ?
                    (<>
                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                <Grid size={{ xs: 3 }} className={`${styles.pdfImgItem}`}>
                                    <img src={pdf} className={`${styles.pdfImg}`} alt="" />
                                </Grid>
                                <Grid size={{ xs: 7 }} className={`${styles.pdfContentItem}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} className={`${styles.pdfNameItem} ${globalStyles.gblOnelineEllipsis}`} sx={{ margin: "5px 0px 5px" }}>
                                            <Typography component={'span'}>
                                                {pdfMainName}
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }} className={`${styles.pdfSubNameItem} ${globalStyles.gblOnelineEllipsis}`}>
                                            <Typography component={'span'}>
                                                {pdfSubName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>) : (<>
                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }}>
                                    <img src={pdf} style={{ width: "50px", height: 'auto' }} alt="" />
                                </Grid>
                                <Grid size={{ xs: 12 }} className={`${styles.pdfNameItem}`} sx={{ margin: "5px 0px 5px" }}>
                                    <Typography component={'span'}>
                                        {pdfMainName}
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }} className={`${styles.pdfSubNameItem}`}>
                                    <Typography component={'span'}>
                                        {pdfSubName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>)
                }
            </Grid>
        </>
    )
}

export default PdfCards
import React from 'react'
import Grid from '@mui/material/Grid2';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "../../Styles/ComponentStyles/GlobalComponentStyles/AppClientMarketingDialog.module.scss"
import { Typography } from '@mui/material';


const SsoDialog = (props: any) => {

    const { setFlagSSODialog, radtailLinkData, setCurrentAppUrl, getRedtailSSO } = props

    console.log("radtailLinkData dialog", radtailLinkData);


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }} sx={{ padding: "20px 0px" }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "40px 0px 20px" }}>
                            <Grid container sx={{ justifyContent: 'space-between', rowGap: "20px", }}>
                                {radtailLinkData && radtailLinkData.length > 0 && (
                                    radtailLinkData.map((ele: any) => {
                                        const lastPartOfUrl = ele.url.split('/').pop();
                                        return (
                                            <>
                                                <Grid size={{ xs: 5.8 }}
                                                    sx={{
                                                        border: '1px solid white',
                                                        padding: '15px',
                                                        borderRadius: "8px",
                                                        transition: 'border-color 0.3s ease',
                                                        '&:hover': {
                                                            borderColor: '#1b76af',
                                                            cursor: 'pointer',
                                                        },
                                                    }}
                                                >
                                                    <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                                        <Grid className={`${styles.iconItem}`}>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: ele.icon }}
                                                            />
                                                        </Grid>
                                                        <Grid className={`${styles.titleItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${styles.ssoTitleLabel}`}
                                                            >
                                                                {ele.title}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid className={`${styles.btnItem}`}>
                                                            <GlobalButton
                                                                fullWidth
                                                                value='View'
                                                                endIcon={<ArrowForwardIcon sx={{ color: "#273348" }} />}
                                                                size='medium'
                                                                className={`${styles.globalSsoBtnStyles}`}
                                                                onClick={() => {
                                                                    setCurrentAppUrl(lastPartOfUrl)
                                                                    getRedtailSSO(lastPartOfUrl)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    })
                                )}
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} sx={{ mt: "10px" }}>
                            <Grid container sx={{ justifyContent: "end", gap: "20px" }}>
                                <Grid sx={{ maxWidth: "130px", width: "130px" }}>
                                    <GlobalButton
                                        fullWidth
                                        value='Close'
                                        size='large'
                                        className={`${styles.closeBtnStyle}`}
                                        onClick={() => {
                                            setCurrentAppUrl(null)
                                            setFlagSSODialog(false)
                                        }}
                                    />
                                </Grid>
                                <Grid sx={{ maxWidth: "130px", width: "130px" }}>
                                    <GlobalButton
                                        fullWidth
                                        value='Uninstall'
                                        size='large'
                                        className={`${styles.globalSsoBtnStyles}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SsoDialog
import { InputBaseComponentProps, SxProps, TextField, TextFieldProps } from '@mui/material'
import React, { ReactNode } from 'react'
import styles from './GlobalTextField.module.scss'


interface textfieldProps {
    stylingClassName?: string;
    sx?: SxProps;
    placeholder?: string;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
    value?: string | number | null;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    type?: string;
    size?: 'small' | 'medium';
    variant?: 'filled' | 'outlined' | 'standard';
    id?: string;
    fullWidth?: boolean;
    autoComplete?: string;
    maxRows?: number;
    children?: ReactNode;
    inputProps?: InputBaseComponentProps;
    InputProps?: TextFieldProps['InputProps'];

    // Add error handling props
    error?: boolean;               // error prop to show error styling
    helperText?: string | false;
}

const GlobalTextfield = (props: textfieldProps) => {

    const {
        sx, size, value, stylingClassName, variant, onChange, type, inputProps, id, disabled, multiline, rows, maxRows, placeholder, onBlur, fullWidth, autoComplete,
        error, helperText
    } = props

    return (
        <>
            <TextField
                sx={sx}
                size={size}
                value={value}
                className={stylingClassName ? stylingClassName : styles.globalTexfieldStyle}
                variant={variant}
                onChange={onChange}
                type={type}
                inputProps={inputProps}
                id={id}
                disabled={disabled}
                multiline={multiline}
                rows={rows}
                maxRows={maxRows}
                placeholder={placeholder}
                onBlur={onBlur}
                fullWidth={fullWidth}
                autoComplete={autoComplete}
                error={error}
                helperText={helperText}
                {...props}
            />
        </>
    )
}


export default GlobalTextfield
import React from 'react'
import styles from "../../Styles/ComponentStyles/MainPages/Leadership.module.scss"
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AppNavbar from '../../Components/AppNavbar/AppNavbar'
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import DashboardNavbar from '../Dashboard/DashboardNavbar/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import { leadershipData } from '../../Utils/Constant/Constant';



const Leaderboard = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/')
    }

    return (
        <>
            {/* <AppNavbar
                navbarHeadingName={"Leadership"}
            /> */}
            <DashboardNavbar
                onClick={handleClick}
                headName="App Access Request"
            />
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <TableContainer>
                        <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                            <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                <TableRow>
                                    <TableCell
                                        className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableMediumWidth}`}
                                        sx={{ fontSize: "14px", borderTopLeftRadius: "5px" }}
                                    >
                                        USER NAME
                                    </TableCell>
                                    <TableCell
                                        className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableLargeWidth}`}
                                        sx={{ fontSize: "14px" }}
                                    >
                                        REQUESTED APP NAME
                                    </TableCell>
                                    <TableCell
                                        className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableSmallWidth}`}
                                        sx={{ fontSize: "14px" }}
                                    >
                                        REQUESTED TIME
                                    </TableCell>
                                    <TableCell
                                        className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblTableLargeWidth}`}
                                        sx={{ fontSize: "14px", borderTopRightRadius: "5px" }}
                                    >
                                        ADMIN
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leadershipData?.map((row: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        className={`${globalStyles.gblTableBodyRow}`}
                                    >
                                        <TableCell
                                            className={`${globalStyles.gblTableCellText}`}
                                        >
                                            {row.userName}
                                        </TableCell>

                                        <TableCell
                                            className={`${globalStyles.gblTableCellText}`}
                                        >
                                            {row.requestedAppName}
                                        </TableCell>

                                        <TableCell
                                            className={`${globalStyles.gblTableCellText}`}
                                        >
                                            {row.requestedTime}
                                        </TableCell>

                                        <TableCell
                                            className={`${globalStyles.gblTableCellText}`}
                                        >
                                            <Grid container sx={{ columnGap: "15px" }}>
                                                <Grid>
                                                    <GlobalButton
                                                        value="Approve"
                                                        size='small'
                                                        variant='contained'
                                                        className={`${styles.approveBtn}`}
                                                        onClick={() => {
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <GlobalButton
                                                        value="Reject"
                                                        size='small'
                                                        variant='contained'
                                                        className={`${styles.rejectBtn}`}
                                                        onClick={() => {
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default Leaderboard
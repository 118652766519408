import React, { useEffect, useRef, useState } from 'react'
import globalStyles from '../../Styles/global.module.scss'
import styles from '../../Styles/ComponentStyles/MainPages/dashboard.module.scss'

import Grid from '@mui/material/Grid2';
import GlobalTabs from '../../Utils/Layout/GlobalTabs/GlobalTabs';
import { Divider, Typography } from '@mui/material';
import KeyIndices from '../KeyIndices/KeyIndices';
import CanvasJSReact from '../../Lib/canvasjs-stockchart-1.10.16/canvasjs.stock.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const KeyIndicesDialog = (props: any) => {

    const { keyIndicesTabs, keyIndicesCurrentTab, handleKeyIndicesTabChange, keyIndicesData, flagOpenKeyIndeciesDialog } = props

    const [ohlcData, setOhlcData] = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        fetch('https://canvasjs.com/data/docs/btcusd2018.json')
            .then((res) => res.json())
            .then((data) => {
                const dps = data.map((item: any) => ({
                    x: new Date(item.date),
                    y: [Number(item.open), Number(item.high), Number(item.low), Number(item.close)],
                }));
                setOhlcData(dps);
                setIsLoaded(true);
            });
    }, []);

    const calculateTrendLine = () => {
        let a, b, c, d, e, slope, yIntercept;
        let chart = chartRef.current;
        let xSum = 0, ySum = 0, xySum = 0, xSquare = 0, dataLength = chart.data[0].dataPoints.length;

        for (let i = 0; i < dataLength; i++) {
            xySum += (chart.data[0].dataPoints[i].x.getTime() * chart.data[0].dataPoints[i].y[3]);
        }
        a = xySum * dataLength;

        for (let i = 0; i < dataLength; i++) {
            xSum += chart.data[0].dataPoints[i].x.getTime();
            ySum += chart.data[0].dataPoints[i].y[3];
        }
        b = xSum * ySum;

        for (let i = 0; i < dataLength; i++) {
            xSquare += Math.pow(chart.data[0].dataPoints[i].x.getTime(), 2);
        }
        c = dataLength * xSquare;

        d = Math.pow(xSum, 2);
        slope = (a - b) / (c - d);
        e = slope * xSum;
        yIntercept = (ySum - e) / dataLength;

        const startPoint = getTrendLinePoint(chart.data[0].dataPoints[0].x, slope, yIntercept);
        const endPoint = getTrendLinePoint(chart.data[0].dataPoints[dataLength - 1].x, slope, yIntercept);

        chart.addTo("data", {
            type: "line",
            markerSize: 0,
            name: "Trend",
            showInLegend: true,
            color: "#E64A19",
            yValueFormatString: "$#,###.00",
            dataPoints: [startPoint, endPoint],
        });
    };

    const getTrendLinePoint = (x: Date, slope: number, intercept: number) => {
        return { x, y: slope * x.getTime() + intercept };
    };

    useEffect(() => {
        if (isLoaded && chartRef.current) {
            calculateTrendLine();
        }
    }, [isLoaded]);

    const options = {
        theme: "light2",
        zoomEnabled: true,
        title: {
            text: "",
        },
        axisY: {
            visible: false,   // Hide the Y-axis completely
            lineThickness: 0, // Remove the line
            tickThickness: 0, // Remove the ticks
            gridThickness: 0, // Remove grid lines
        },
        axisX: {
            valueFormatString: "MMM",
        },
        legend: {
            verticalAlign: "bottom",
            cursor: "pointer",
            itemclick: function (e: any) {
                if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            },
        },
        toolTip: {
            shared: true,
        },
        data: [
            {
                type: "ohlc",
                showInLegend: true,
                name: "Bitcoin Price",
                yValueFormatString: "$#,###.00",
                xValueType: "dateTime",
                dataPoints: ohlcData,
            },
        ],
    };



    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (flagOpenKeyIndeciesDialog) {
            const scriptId = "tradingview-widget-script-fullview";
            const existingScript = document.getElementById(scriptId);

            if (existingScript) {
                existingScript.remove();
            }

            const script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
            script.async = true;

            script.innerHTML = JSON.stringify({
                colorTheme: "light", // Set to "dark" for a dark theme
                dateRange: "1D", // Default time range for charts
                showChart: true, // Enables the line charts at the top
                locale: "en",
                isTransparent: false,
                width: "100%",
                height: "600", // Adjust height based on requirements
                showSymbolLogo: true,

                tabs: [
                    {
                        title: "Indices",
                        symbols: [
                            { s: "FOREXCOM:SPXUSD", d: "S&P 500 Index" },
                            { s: "FOREXCOM:NSXUSD", d: "US 100 Cash CFD" },
                            { s: "FOREXCOM:DJI", d: "Dow Jones Industrial Average Index" },
                            { s: "INDEX:NKY", d: "Japan 225" },
                            { s: "INDEX:DEU40", d: "DAX Index" },
                            { s: "FOREXCOM:UKXGBP", d: "FTSE 100 Index" }
                        ],
                        originalTitle: "Indices"
                    },
                    {
                        title: "Futures",
                        symbols: [
                            { s: "CME_MINI:ES1!", d: "S&P 500" },
                            { s: "CME:6E1!", d: "Euro" },
                            { s: "COMEX:GC1!", d: "Gold" },
                            { s: "NYMEX:CL1!", d: "WTI Crude Oil" },
                            { s: "NYMEX:NG1!", d: "Gas" },
                            { s: "CBOT:ZC1!", d: "Corn" }
                        ],
                        originalTitle: "Futures"
                    },
                    {
                        title: "Bonds",
                        symbols: [
                            { s: "CBOT:ZB1!", d: "T-Bond" },
                            { s: "CBOT:UB1!", d: "Ultra T-Bond" },
                            { s: "EUREX:FGBL1!", d: "Euro Bund" },
                            { s: "EUREX:FBTP1!", d: "Euro BTP" },
                            { s: "EUREX:FGBM1!", d: "Euro BOBL" }
                        ],
                        originalTitle: "Bonds"
                    },
                    {
                        title: "Forex",
                        symbols: [
                            { s: "FX:EURUSD", d: "EUR to USD" },
                            { s: "FX:GBPUSD", d: "GBP to USD" },
                            { s: "FX:USDJPY", d: "USD to JPY" },
                            { s: "FX:USDCHF", d: "USD to CHF" },
                            { s: "FX:AUDUSD", d: "AUD to USD" },
                            { s: "FX:USDCAD", d: "USD to CAD" }
                        ],
                        originalTitle: "Forex"
                    }
                ],
            });

            if (containerRef.current) {
                containerRef.current.innerHTML = "";
                containerRef.current.appendChild(script);
            }
        }
    }, [flagOpenKeyIndeciesDialog]);







    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        {/* <Grid size={{ xs: 12 }} className={`${globalStyles.globalTabMainItem}`}>
                            <GlobalTabs
                                tabs={keyIndicesTabs}
                                currentTab={keyIndicesCurrentTab}
                                onChange={handleKeyIndicesTabChange}
                                sxTabStyle={{
                                    textTransform: 'none',
                                    color: '#7E7E7E',
                                    marginRight: '20px',
                                    fontWeight: 'normal',
                                    fontSize: '1rem',
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                        fontWeight: 'bold',
                                    }
                                }}
                            />
                        </Grid> */}

                        {/* <Grid size={{ xs: 12 }}>
                            <CanvasJSChart options={options} onRef={(ref: any) => (chartRef.current = ref)} />
                        </Grid> */}

                        <Grid size={{ xs: 12 }} sx={{ margin: "15px 0px 15px" }}>
                            <Grid container>
                                <div
                                    ref={containerRef}
                                    style={{ width: "100%", height: "600px" }}
                                    className="tradingview-widget-container"
                                >
                                    <div className="tradingview-widget-container__widget"></div>
                                </div>
                                {/* <KeyIndices
                                    keyIndicesData={keyIndicesData}
                                    flagVisibleDivider={false}
                                /> */}
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default KeyIndicesDialog
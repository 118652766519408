export default function Icons(props: any) {

    const { iconName, iconWidth, iconHeight, iconColor } = props

    return (
        <>
            {iconName == "arrow" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="white" />
                    <path d="M10.1761 4.15988L5.72193 8.61409C5.619 8.71701 5.5625 8.85425 5.5625 9.00376C5.5625 9.15026 5.619 9.28758 5.72193 9.39034L10.1718 13.8404C10.2748 13.9433 10.4122 14 10.5587 14C10.7052 14 10.8426 13.9434 10.9455 13.8404L11.2732 13.5127C11.3761 13.4098 11.433 13.2724 11.433 13.1259C11.433 12.9794 11.3761 12.842 11.2732 12.739L7.53639 9.00222L11.2775 5.26126C11.3804 5.15833 11.4371 5.02101 11.4371 4.87443C11.4371 4.72792 11.3804 4.59069 11.2775 4.48768L10.9496 4.15988C10.7364 3.94671 10.3893 3.94671 10.1761 4.15988Z" fill={iconColor ? iconColor : "#ffffff"} />
                </svg>
            }
            {iconName == "401k" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.815 2.49756L14.625 3.81006L12.4425 2.49756L10.2525 3.81006L8.07 2.49756L5.88 3.81006L3.69 2.49756L1.5 3.81006V19.7101C1.5 20.7001 2.3025 21.5026 3.2925 21.5026H13.914C12.3307 20.3851 11.292 18.5453 11.292 16.4648C11.292 13.0643 14.058 10.2983 17.4585 10.2983C17.991 10.2983 18.5047 10.3733 18.9975 10.5008V3.81006L16.815 2.49756ZM9.74025 17.0183H5.14425C4.7715 17.0183 4.46925 16.7161 4.46925 16.3433C4.46925 15.9706 4.7715 15.6683 5.14425 15.6683H9.74025C10.113 15.6683 10.4153 15.9706 10.4153 16.3433C10.4153 16.7161 10.113 17.0183 9.74025 17.0183ZM10.8075 13.3388H5.145C4.77225 13.3388 4.47 13.0366 4.47 12.6638C4.47 12.2911 4.77225 11.9888 5.145 11.9888H10.8075C11.1802 11.9888 11.4825 12.2911 11.4825 12.6638C11.4825 13.0366 11.1802 13.3388 10.8075 13.3388ZM15.3563 9.65331H5.14425C4.7715 9.65331 4.46925 9.35106 4.46925 8.97831C4.46925 8.60556 4.7715 8.30331 5.14425 8.30331H15.3563C15.729 8.30331 16.0312 8.60556 16.0312 8.97831C16.0312 9.35106 15.729 9.65331 15.3563 9.65331Z" fill={iconColor ? iconColor : "#ffffff"} />
                    <path d="M17.4595 11.4233C14.6747 11.4233 12.418 13.6808 12.418 16.4648C12.418 19.2488 14.6755 21.5063 17.4595 21.5063C20.2435 21.5063 22.501 19.2488 22.501 16.4648C22.501 13.6808 20.2435 11.4233 17.4595 11.4233ZM18.1322 18.8896V19.0328C18.1322 19.4056 17.83 19.7078 17.4572 19.7078C17.0935 19.7078 16.801 19.4183 16.7875 19.0576C16.5377 19.0216 16.3015 18.9113 16.1185 18.7351C15.8815 18.5056 15.7502 18.1981 15.7502 17.8681C15.7502 17.4953 16.0525 17.1931 16.4252 17.1931C16.747 17.1931 17.0155 17.4173 17.0837 17.7188C17.6072 17.6828 18.0055 17.5448 18.013 17.3881C18.0152 17.3963 17.8862 17.2561 17.3065 17.1233C16.0907 16.8473 15.5027 16.2871 15.5597 15.4568C15.589 15.0233 15.8102 14.3626 16.783 14.0416V13.8968C16.783 13.5241 17.0852 13.2218 17.458 13.2218C17.8225 13.2218 18.1142 13.5113 18.1277 13.8728C18.3752 13.9088 18.6085 14.0146 18.793 14.1931C19.0277 14.4196 19.162 14.7368 19.162 15.0631C19.162 15.4358 18.8597 15.7381 18.487 15.7381C18.166 15.7381 17.8967 15.5138 17.8285 15.2131C17.2825 15.2491 16.918 15.3796 16.9067 15.5491C16.9052 15.5416 17.0042 15.6706 17.6057 15.8071C19.0847 16.1438 19.3772 16.8698 19.363 17.4203C19.3525 17.7878 19.192 18.5401 18.1322 18.8896Z" fill={iconColor ? iconColor : "#ffffff"} />
                </svg>
            }
            {iconName == "revenue" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_195_190)">
                        <path d="M7.99269 4.5H16.0087L17.7387 3.116C18.3277 2.646 18.5477 1.883 18.2987 1.171C18.0497 0.459 17.4027 0 16.6507 0H7.35069C6.59869 0 5.95169 0.46 5.70269 1.171C5.45369 1.882 5.67369 2.646 6.26169 3.115L7.99269 4.5Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M16.145 6H7.855C5.124 8.681 3 13.385 3 17.25C3 20.609 4.778 24 8.75 24H15.5C18.893 24 21 21.413 21 17.25C21 13.385 18.876 8.681 16.145 6ZM11.62 14.25H12.38C13.549 14.25 14.5 15.201 14.5 16.37C14.5 17.421 13.742 18.286 12.75 18.462V19.249C12.75 19.663 12.414 19.999 12 19.999C11.586 19.999 11.25 19.663 11.25 19.249V18.5H10.25C9.836 18.5 9.5 18.164 9.5 17.75C9.5 17.336 9.836 17 10.25 17H12.38C12.722 17 13 16.722 13 16.38C13 16.028 12.722 15.75 12.38 15.75H11.62C10.451 15.75 9.5 14.799 9.5 13.63C9.5 12.579 10.258 11.714 11.25 11.538V10.75C11.25 10.336 11.586 10 12 10C12.414 10 12.75 10.336 12.75 10.75V11.5H13.75C14.164 11.5 14.5 11.836 14.5 12.25C14.5 12.664 14.164 13 13.75 13H11.62C11.278 13 11 13.278 11 13.62C11 13.972 11.278 14.25 11.62 14.25Z" fill={iconColor ? iconColor : "#ffffff"} />
                    </g>
                    <defs>
                        <clipPath id="clip0_195_190">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "revenue" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_195_190)">
                        <path d="M7.99269 4.5H16.0087L17.7387 3.116C18.3277 2.646 18.5477 1.883 18.2987 1.171C18.0497 0.459 17.4027 0 16.6507 0H7.35069C6.59869 0 5.95169 0.46 5.70269 1.171C5.45369 1.882 5.67369 2.646 6.26169 3.115L7.99269 4.5Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M16.145 6H7.855C5.124 8.681 3 13.385 3 17.25C3 20.609 4.778 24 8.75 24H15.5C18.893 24 21 21.413 21 17.25C21 13.385 18.876 8.681 16.145 6ZM11.62 14.25H12.38C13.549 14.25 14.5 15.201 14.5 16.37C14.5 17.421 13.742 18.286 12.75 18.462V19.249C12.75 19.663 12.414 19.999 12 19.999C11.586 19.999 11.25 19.663 11.25 19.249V18.5H10.25C9.836 18.5 9.5 18.164 9.5 17.75C9.5 17.336 9.836 17 10.25 17H12.38C12.722 17 13 16.722 13 16.38C13 16.028 12.722 15.75 12.38 15.75H11.62C10.451 15.75 9.5 14.799 9.5 13.63C9.5 12.579 10.258 11.714 11.25 11.538V10.75C11.25 10.336 11.586 10 12 10C12.414 10 12.75 10.336 12.75 10.75V11.5H13.75C14.164 11.5 14.5 11.836 14.5 12.25C14.5 12.664 14.164 13 13.75 13H11.62C11.278 13 11 13.278 11 13.62C11 13.972 11.278 14.25 11.62 14.25Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_195_190">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "application" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z" fill={iconColor ? iconColor : "#ffffff"} />
                    <path d="M18.6695 2H16.7695C14.5895 2 13.4395 3.15 13.4395 5.33V7.23C13.4395 9.41 14.5895 10.56 16.7695 10.56H18.6695C20.8495 10.56 21.9995 9.41 21.9995 7.23V5.33C21.9995 3.15 20.8495 2 18.6695 2Z" fill={iconColor ? iconColor : "#ffffff"} />
                    <path d="M18.6695 13.4299H16.7695C14.5895 13.4299 13.4395 14.5799 13.4395 16.7599V18.6599C13.4395 20.8399 14.5895 21.9899 16.7695 21.9899H18.6695C20.8495 21.9899 21.9995 20.8399 21.9995 18.6599V16.7599C21.9995 14.5799 20.8495 13.4299 18.6695 13.4299Z" fill={iconColor ? iconColor : "#ffffff"} />
                    <path d="M7.24 13.4299H5.34C3.15 13.4299 2 14.5799 2 16.7599V18.6599C2 20.8499 3.15 21.9999 5.33 21.9999H7.23C9.41 21.9999 10.56 20.8499 10.56 18.6699V16.7699C10.57 14.5799 9.42 13.4299 7.24 13.4299Z" fill={iconColor ? iconColor : "#ffffff"} />
                </svg>
            }
            {iconName == "leaderboard" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_195_177)">
                        <path d="M17.4101 3.92878C17.3176 3.64458 17.0717 3.4373 16.7763 3.39441L13.9646 2.98575L12.7067 0.437531C12.5748 0.169687 12.3019 0 12.0029 0C11.7039 0 11.4309 0.169687 11.2991 0.437531L10.0412 2.9858L7.22894 3.39445C6.93349 3.43739 6.68763 3.64462 6.59514 3.92883C6.50266 4.21303 6.57982 4.52508 6.79399 4.73362L8.82935 6.71709L8.34841 9.51759C8.29778 9.81225 8.41896 10.11 8.66074 10.2856C8.90303 10.4619 9.22352 10.4836 9.48775 10.3454L12.0031 9.02311L14.5179 10.3454C14.7882 10.4872 15.1074 10.458 15.3449 10.2856C15.5866 10.11 15.7078 9.8123 15.6572 9.51787L15.1767 6.71709L17.2116 4.73362C17.4255 4.52508 17.5021 4.21298 17.4101 3.92878Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M15.1401 11.9619H8.85933C8.42573 11.9619 8.07422 12.3134 8.07422 12.747V24.0001H15.9252V12.747C15.9252 12.3134 15.5737 11.9619 15.1401 11.9619Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M1.00777 15.1023C0.574172 15.1023 0.222656 15.4538 0.222656 15.8874V23.3794C0.222656 23.7222 0.500578 24.0001 0.843375 24.0001H6.50348V15.1023H1.00777Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M22.9918 18.2427H17.4961V24.0001H23.1562C23.499 24.0001 23.7769 23.7222 23.7769 23.3794V19.0278C23.7769 18.5942 23.4254 18.2427 22.9918 18.2427Z" fill={iconColor ? iconColor : "#ffffff"} />
                    </g>
                    <defs>
                        <clipPath id="clip0_195_177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "member" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_195_162)">
                        <path d="M4 9C5.65685 9 7 7.65685 7 6C7 4.34315 5.65685 3 4 3C2.34315 3 1 4.34315 1 6C1 7.65685 2.34315 9 4 9Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M7.29 11.07C6.2835 11.6981 5.45339 12.5719 4.87782 13.6094C4.30226 14.6468 4.00017 15.8136 4 17H2C1.47005 16.9984 0.962265 16.7872 0.587535 16.4125C0.212805 16.0377 0.00158273 15.5299 0 15L0 13C0.00237409 12.2051 0.319207 11.4434 0.881302 10.8813C1.4434 10.3192 2.20508 10.0024 3 10H5C5.43657 10.001 5.86767 10.0972 6.26319 10.2821C6.65872 10.4669 7.00914 10.7358 7.29 11.07Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M20 9C21.6569 9 23 7.65685 23 6C23 4.34315 21.6569 3 20 3C18.3431 3 17 4.34315 17 6C17 7.65685 18.3431 9 20 9Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M24.0009 13V15C23.9994 15.5299 23.7881 16.0377 23.4134 16.4125C23.0387 16.7872 22.5309 16.9984 22.0009 17H20.0009C20.0008 15.8136 19.6987 14.6468 19.1231 13.6094C18.5475 12.5719 17.7174 11.6981 16.7109 11.07C16.9918 10.7358 17.3422 10.4669 17.7377 10.2821C18.1333 10.0972 18.5644 10.001 19.0009 10H21.0009C21.7959 10.0024 22.5575 10.3192 23.1196 10.8813C23.6817 11.4434 23.9986 12.2051 24.0009 13Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M18 17V18C17.9976 18.7949 17.6808 19.5566 17.1187 20.1187C16.5566 20.6808 15.7949 20.9976 15 21H9C8.20508 20.9976 7.4434 20.6808 6.8813 20.1187C6.31921 19.5566 6.00237 18.7949 6 18V17C6 15.6739 6.52678 14.4021 7.46447 13.4645C8.40215 12.5268 9.67392 12 11 12H13C14.3261 12 15.5979 12.5268 16.5355 13.4645C17.4732 14.4021 18 15.6739 18 17Z" fill={iconColor ? iconColor : "#ffffff"} />
                    </g>
                    <defs>
                        <clipPath id="clip0_195_162">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "dashboard" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_117_2834)">
                        <path d="M9.24994 0H1.74994C0.784973 0 0 0.784973 0 1.74994V6.24994C0 7.21509 0.784973 8.00006 1.74994 8.00006H9.24994C10.2151 8.00006 11.0001 7.21509 11.0001 6.24994V1.74994C11.0001 0.784973 10.2151 0 9.24994 0Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M9.24994 10H1.74994C0.784973 10 0 10.785 0 11.7501V22.2501C0 23.2151 0.784973 24.0001 1.74994 24.0001H9.24994C10.2151 24.0001 11.0001 23.2151 11.0001 22.2501V11.7501C11.0001 10.785 10.2151 10 9.24994 10Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M22.2501 16H14.7501C13.785 16 13 16.785 13 17.7501V22.2501C13 23.2151 13.785 24.0001 14.7501 24.0001H22.2501C23.2151 24.0001 24.0001 23.2151 24.0001 22.2501V17.7501C24.0001 16.785 23.2151 16 22.2501 16Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M22.2501 0H14.7501C13.785 0 13 0.784973 13 1.74994V12.2499C13 13.2151 13.785 14.0001 14.7501 14.0001H22.2501C23.2151 14.0001 24.0001 13.2151 24.0001 12.2499V1.74994C24.0001 0.784973 23.2151 0 22.2501 0Z" fill={iconColor ? iconColor : "#ffffff"} />
                    </g>
                    <defs>
                        <clipPath id="clip0_117_2834">
                            <rect width="20px" height="20px" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "downloadIcon" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L2.25 5.25L3.3 4.1625L5.25 6.1125V0H6.75V6.1125L8.7 4.1625L9.75 5.25L6 9ZM1.5 12C1.0875 12 0.7345 11.8533 0.441 11.5597C0.1475 11.2662 0.0005 10.913 0 10.5V8.25H1.5V10.5H10.5V8.25H12V10.5C12 10.9125 11.8533 11.2657 11.5597 11.5597C11.2662 11.8538 10.913 12.0005 10.5 12H1.5Z" fill={iconColor ? iconColor : "#ffffff"} />
                </svg>
            }
            {iconName == "warningIcon" &&
                <svg width="85" height="75" viewBox="0 0 85 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.8965 3.5928L0.9634 64.0944C0.3324 65.1869 0.000100023 66.4264 2.25837e-08 67.6881C-9.99774e-05 68.9498 0.3319 70.1893 0.9627 71.282C1.5935 72.3746 2.5009 73.282 3.5936 73.9128C4.6862 74.5437 5.9257 74.8757 7.1874 74.8756H77.0481C78.3098 74.8757 79.5493 74.5437 80.6419 73.9128C81.7346 73.282 82.6416 72.3746 83.2726 71.282C83.9036 70.1893 84.2356 68.9498 84.2356 67.6881C84.2356 66.4264 83.9036 65.1869 83.2726 64.0944L48.3427 3.5928C47.712 2.5004 46.8049 1.5933 45.7125 0.9627C44.6201 0.332 43.381 0 42.1196 0C40.8583 0 39.6191 0.332 38.5267 0.9627C37.4343 1.5933 36.5272 2.5004 35.8965 3.5928Z" fill="#EE404C" />
                    <path d="M42.575 22.252H41.6638C39.4203 22.252 37.6016 24.0707 37.6016 26.3142V45.7308C37.6016 47.9742 39.4203 49.793 41.6638 49.793H42.575C44.8185 49.793 46.6372 47.9742 46.6372 45.7308V26.3142C46.6372 24.0707 44.8185 22.252 42.575 22.252Z" fill="#FFF7ED" />
                    <path d="M42.1194 65.1919C44.6145 65.1919 46.6372 63.1691 46.6372 60.674C46.6372 58.1789 44.6145 56.1562 42.1194 56.1562C39.6243 56.1562 37.6016 58.1789 37.6016 60.674C37.6016 63.1691 39.6243 65.1919 42.1194 65.1919Z" fill="#FFF7ED" />
                </svg>
            }
            {iconName == "searchIcon" &&
                <svg width={iconWidth ? iconWidth : 76} height={iconHeight ? iconHeight : 76} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.80524 11.7137C1.9404 10.8306 1.35381 9.71307 1.11824 8.49969C0.880725 7.28291 1.00269 6.0233 1.46924 4.87469C1.93181 3.73253 2.72153 2.75251 3.73924 2.05769C5.80409 0.647436 8.52239 0.647436 10.5872 2.05769C11.605 2.75251 12.3947 3.73253 12.8572 4.87469C13.3238 6.0233 13.4458 7.28291 13.2082 8.49969C12.9727 9.71307 12.3861 10.8306 11.5212 11.7137C10.3759 12.889 8.80436 13.5519 7.16324 13.5519C5.52213 13.5519 3.95059 12.889 2.80524 11.7137V11.7137Z" stroke={iconColor ? iconColor : "#FFF7ED"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.16999 3.20292C6.75809 3.24656 6.45955 3.61586 6.5032 4.02777C6.54685 4.43968 6.91614 4.73821 7.32805 4.69457L7.16999 3.20292ZM9.02136 5.69213C9.18285 6.07357 9.62297 6.25188 10.0044 6.0904C10.3859 5.92892 10.5642 5.4888 10.4027 5.10736L9.02136 5.69213ZM12.1419 11.0869C11.8487 10.7943 11.3739 10.7947 11.0812 11.0879C10.7886 11.381 10.789 11.8559 11.0822 12.1485L12.1419 11.0869ZM14.4702 15.5305C14.7633 15.8232 15.2382 15.8228 15.5308 15.5296C15.8235 15.2364 15.823 14.7616 15.5299 14.4689L14.4702 15.5305ZM7.32805 4.69457C8.05056 4.61801 8.73812 5.02306 9.02136 5.69213L10.4027 5.10736C9.86194 3.83005 8.54933 3.05676 7.16999 3.20292L7.32805 4.69457ZM11.0822 12.1485L14.4702 15.5305L15.5299 14.4689L12.1419 11.0869L11.0822 12.1485Z" fill={iconColor ? iconColor : "#FFF7ED"} />
                </svg>
            }
            {iconName == "checkbox" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill={iconColor ? iconColor : "#FFFFFF"} fill-opacity="0.2" stroke="white" />
                </svg>
            }
            {iconName == "checkedIcon" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="18" height="18" rx="3" fill="white" />
                    <g clip-path="url(#clip0_502_152)">
                        <path d="M8.31176 12.918L15.5 5.06009L14.4605 4L7.30625 11.8229L3.52766 7.714L2.5 8.78561L6.30226 12.9208L7.32163 14L8.31176 12.918Z" fill={iconColor ? iconColor : "#273348"} />
                    </g>
                    <defs>
                        <clipPath id="clip0_502_152">
                            <rect width="13" height="10" fill="white" transform="translate(2.5 4)" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "addIcon" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.90697 0.280614C7.29202 -0.0935381 10.7081 -0.0935381 14.0932 0.280614C15.9674 0.490325 17.4794 1.96376 17.6993 3.84133C18.1005 7.26879 18.1005 10.7312 17.6993 14.1587C17.4794 16.0362 15.9674 17.5097 14.0932 17.7194C10.7081 18.0935 7.29202 18.0935 3.90697 17.7194C2.03276 17.5097 0.520697 16.0362 0.300781 14.1587C-0.10026 10.7316 -0.10026 7.26952 0.300781 3.84242C0.412017 2.93026 0.828558 2.08232 1.4829 1.43604C2.13723 0.789757 2.99102 0.383005 3.90588 0.281706M9.00006 3.54642C9.21769 3.54642 9.42641 3.63273 9.5803 3.78636C9.73419 3.93998 9.82065 4.14835 9.82065 4.36561V8.18082H13.6424C13.86 8.18082 14.0687 8.26712 14.2226 8.42075C14.3765 8.57438 14.463 8.78274 14.463 9C14.463 9.21726 14.3765 9.42562 14.2226 9.57925C14.0687 9.73288 13.86 9.81918 13.6424 9.81918H9.82065V13.6344C9.82065 13.8517 9.73419 14.06 9.5803 14.2136C9.42641 14.3673 9.21769 14.4536 9.00006 14.4536C8.78243 14.4536 8.57371 14.3673 8.41982 14.2136C8.26593 14.06 8.17948 13.8517 8.17948 13.6344V9.81918H4.35775C4.14011 9.81918 3.9314 9.73288 3.77751 9.57925C3.62362 9.42562 3.53716 9.21726 3.53716 9C3.53716 8.78274 3.62362 8.57438 3.77751 8.42075C3.9314 8.26712 4.14011 8.18082 4.35775 8.18082H8.17948V4.36561C8.17948 4.14835 8.26593 3.93998 8.41982 3.78636C8.57371 3.63273 8.78243 3.54642 9.00006 3.54642Z" fill={iconColor ? iconColor : "#273348"} />
                </svg>
            }
            {iconName == "moneyIcon" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_51_10120)">
                        <path d="M7.99171 4.5H16.0077L17.7377 3.116C18.3267 2.646 18.5467 1.883 18.2977 1.171C18.0487 0.459 17.4017 0 16.6497 0H7.34971C6.59771 0 5.95071 0.46 5.70171 1.171C5.45271 1.882 5.67271 2.646 6.26071 3.115L7.99171 4.5Z" fill={iconColor ? iconColor : "#ffffff"} />
                        <path d="M16.145 6H7.855C5.124 8.681 3 13.385 3 17.25C3 20.609 4.778 24 8.75 24H15.5C18.893 24 21 21.413 21 17.25C21 13.385 18.876 8.681 16.145 6ZM11.62 14.25H12.38C13.549 14.25 14.5 15.201 14.5 16.37C14.5 17.421 13.742 18.286 12.75 18.462V19.249C12.75 19.663 12.414 19.999 12 19.999C11.586 19.999 11.25 19.663 11.25 19.249V18.5H10.25C9.836 18.5 9.5 18.164 9.5 17.75C9.5 17.336 9.836 17 10.25 17H12.38C12.722 17 13 16.722 13 16.38C13 16.028 12.722 15.75 12.38 15.75H11.62C10.451 15.75 9.5 14.799 9.5 13.63C9.5 12.579 10.258 11.714 11.25 11.538V10.75C11.25 10.336 11.586 10 12 10C12.414 10 12.75 10.336 12.75 10.75V11.5H13.75C14.164 11.5 14.5 11.836 14.5 12.25C14.5 12.664 14.164 13 13.75 13H11.62C11.278 13 11 13.278 11 13.62C11 13.972 11.278 14.25 11.62 14.25Z" fill={iconColor ? iconColor : "#ffffff"} />
                    </g>
                    <defs>
                        <clipPath id="clip0_51_10120">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            {iconName == "result401kIcon" &&
                <svg width={iconWidth ? iconWidth : 18} height={iconHeight ? iconHeight : 18} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.815 0.498047L13.625 1.81055L11.4425 0.498047L9.2525 1.81055L7.07 0.498047L4.88 1.81055L2.69 0.498047L0.5 1.81055V17.7105C0.5 18.7005 1.3025 19.503 2.2925 19.503H12.914C11.3307 18.3855 10.292 16.5458 10.292 14.4653C10.292 11.0648 13.058 8.2988 16.4585 8.2988C16.991 8.2988 17.5047 8.3738 17.9975 8.5013V1.81055L15.815 0.498047ZM8.74025 15.0188H4.14425C3.7715 15.0188 3.46925 14.7165 3.46925 14.3438C3.46925 13.971 3.7715 13.6688 4.14425 13.6688H8.74025C9.113 13.6688 9.41525 13.971 9.41525 14.3438C9.41525 14.7165 9.113 15.0188 8.74025 15.0188ZM9.8075 11.3393H4.145C3.77225 11.3393 3.47 11.037 3.47 10.6643C3.47 10.2915 3.77225 9.9893 4.145 9.9893H9.8075C10.1803 9.9893 10.4825 10.2915 10.4825 10.6643C10.4825 11.037 10.1803 11.3393 9.8075 11.3393ZM14.3563 7.6538H4.14425C3.7715 7.6538 3.46925 7.35155 3.46925 6.9788C3.46925 6.60605 3.7715 6.3038 4.14425 6.3038H14.3563C14.729 6.3038 15.0312 6.60605 15.0312 6.9788C15.0312 7.35155 14.729 7.6538 14.3563 7.6538Z" fill={iconColor ? iconColor : "#ffffff"} />
                </svg>
            }
        </>
    );
}
import Grid from '@mui/material/Grid2';
import React, { CSSProperties } from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/FeatureCards.module.scss'
import { Typography } from '@mui/material';

interface featureCardProps {
    featureName: string,
    featureImg: any,
    featureStyling: CSSProperties;
    onClick?: any
}

const FeaturedCards = React.memo(function FeaturedCards(props: featureCardProps) {
    const { featureName, featureImg, featureStyling, onClick } = props;

    return (
        <div style={{ width: '100%' }} onClick={onClick}>
            <Grid size={{ xs: 12 }} className={`${styles.featureCardImgItem}`}>
                <img
                    src={featureImg}
                    className={`${styles.featureImages}`}
                    alt=""
                />
            </Grid>
            <Grid size={{ xs: 12 }} className={`${styles.featureCardTextItem}`}>
                <Typography component="span" sx={featureStyling}>
                    {featureName}
                </Typography>
            </Grid>
        </div>
    );
});


export default FeaturedCards















// import Grid from '@mui/material/Grid2';
// import React, { CSSProperties } from 'react'
// import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/FeatureCards.module.scss'
// import { Typography } from '@mui/material';

// interface featureCardProps {
//     featureName: string,
//     featureImg: any,
//     featureStyling: CSSProperties;
//     onClick?: any
// }

// const FeaturedCards = (props: featureCardProps) => {

//     const { featureName, featureImg, featureStyling, onClick } = props

//     return (
//         <>
//             <div style={{ width: "100%" }} onClick={onClick}>
//                 <Grid size={{ xs: 12 }} className={`${styles.featureCardImgItem} `}>
//                     <img
//                         src={featureImg}
//                         alt=""
//                         style={{
//                             width: "150px",
//                             height: "132px",
//                             objectFit: 'fill',
//                             borderRadius: "10px",
//                         }}
//                     />
//                 </Grid>
//                 <Grid size={{ xs: 12 }} className={`${styles.featureCardTextItem}`}>
//                     <Typography component={'span'}
//                         sx={featureStyling}
//                     >
//                         {featureName}
//                     </Typography>
//                 </Grid>
//             </div>
//         </>
//     )
// }

// export default FeaturedCards
import React, { useContext } from 'react'
import sideNavStyles from "../../Styles/ComponentStyles/GlobalComponentStyles/SideNavigationPanel.module.scss"

import Grid from '@mui/material/Grid2';
import acrylicFinancial from '../../Assets/Logos/AcrylicFinanciallogo.png'
import myadvisoryLogo from '../../Assets/Logos/myadviosrylogo.png'
import globalStyles from '../../Styles/global.module.scss'
import profile from '../../Assets/General/profile.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    AppBar,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Typography,
    Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext';
import logo from "../../Assets/Logos/login-mac-logo.png"

const AdminAppNavbar = () => {

    const {
        userProfileDetails,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const capitalize = (str: string) => {
        if (!str) return "";
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
    const userName = userProfileDetails?.user?.firstName && userProfileDetails?.user?.lastName && `${capitalize(userProfileDetails?.user?.firstName)} ${capitalize(userProfileDetails?.user?.lastName)}`;
    const userEmail = userProfileDetails?.user?.email


    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <AppBar component={'nav'} className={`${globalStyles.appBarNav}`}>

                <Grid container>
                    <Grid size={{ xs: 12 }} className={`${globalStyles.appNavMainItem}`}>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: "grow" }}>
                                <img src={logo} className={`${sideNavStyles.appLogo}`} alt="" />
                            </Grid>
                            <Grid size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: "auto" }}>
                                <Grid container sx={{ justifyContent: 'end' }}>
                                    <Grid className={`${globalStyles.navbarProfileItem}`}>
                                        <Grid container className={`${globalStyles.navProfileContainr}`}>
                                            <Grid className={`${globalStyles.profileItem}`}>
                                                <Avatar
                                                    src={profile}
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                />
                                            </Grid>
                                            <Grid className={`${globalStyles.profileArrowItem}`}>
                                                <IconButton
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        setAnchorEl(event.currentTarget);
                                                    }}
                                                    sx={{ padding: "0px" }}
                                                >
                                                    <KeyboardArrowDownIcon />
                                                </IconButton>
                                            </Grid>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                        marginRight: "0px",
                                                        marginTop: "15px",
                                                        marginLeft: "20px",
                                                        width: "220px"
                                                    }
                                                }}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Grid container sx={{ padding: "0px 10px" }}>
                                                    <Grid size={{ xs: 12 }}>
                                                        <Grid container className={`${globalStyles.sideNavProfileContainer}`}>
                                                            <Grid size={{ xs: 2 }} className={`${globalStyles.sideNavImg}`}>
                                                                <Avatar
                                                                    src={profile}
                                                                    sx={{
                                                                        width: "30px",
                                                                        height: "30px"
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid size={{ xs: 9.5 }} className={`${globalStyles.sideNavAdminItem}`}>
                                                                <Grid container>
                                                                    <Grid size={{ xs: 12 }}>
                                                                        <Typography
                                                                            component={'span'}
                                                                            className={`${globalStyles.globalTextV14} ${sideNavStyles.ellipsisStyles}`}
                                                                            sx={{

                                                                            }}
                                                                        >
                                                                            {userName ? userName : ""}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid size={{ xs: 12 }}>
                                                                        <Typography
                                                                            component={'span'}
                                                                            className={`${globalStyles.globalTextV14} ${sideNavStyles.ellipsisStyles}`}
                                                                        >
                                                                            {userEmail ? userEmail : ""}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                                        <Divider sx={{ borderColor: "#D1D1D1" }} />
                                                    </Grid>
                                                </Grid>
                                                <MenuItem
                                                    onClick={(event: any) => {
                                                        navigate('/profile');
                                                        handleClose()
                                                    }}
                                                >
                                                    Profile
                                                </MenuItem>
                                                <MenuItem onClick={() => { handleClose() }}>
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
        </>
    )
}

export default AdminAppNavbar
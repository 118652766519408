import React, { useContext, useEffect, useRef, useState } from 'react'

// STYLES
import styles from '../../../Styles/ComponentStyles/MainPages/dashboard.module.scss'
import globalStyles from '../../../Styles/global.module.scss'
import '../../../Styles/ComponentStyles/MainPages/TradingView.css'

import Grid from '@mui/material/Grid2';
import { Divider, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { tabsConfig, keyIndicesTabs, keyIndicesData, baseURL } from '../../../Utils/Constant/Constant';
import GlobalButton from '../../../Utils/Layout/GlobalButton/GlobalButton';
import GlobalTabs from '../../../Utils/Layout/GlobalTabs/GlobalTabs';


// IMG
import orion from '../../../Assets/Dashboard/orion-advisor-solutions.png'
import revenue from '../../../Assets/Dashboard/advisor.png'
import market from '../../../Assets/Dashboard/market-and-sales.png'
import FeaturedCards from '../../../Components/FeaturedCards/FeaturedCards';

import img1 from '../../../Assets/Dashboard/clien-marketing.png'
import img2 from '../../../Assets/Dashboard/brokersalliance.png'
import img3 from '../../../Assets/Dashboard/lifebrain.png'
import img4 from '../../../Assets/Dashboard/retirementbrain.png';
import img5 from '../../../Assets/Dashboard/fa-portfolio-analysis.png';
import varifiedLogo from '../../../Assets/Dashboard/dash-varified-secure-logo.png';
import image401k from '../../../Assets/Dashboard/401k.png'

import lifebrain from '../../../Assets/Dashboard/lifebrain-bg.png'
import AppNavbar from '../../../Components/AppNavbar/AppNavbar';
import GlobalDialog from '../../../Utils/Layout/GlobalDialog/GlobalDialog';
import KeyIndecesDialog from '../../../Components/Dialogs/KeyIndicesDialog';
import KeyIndices from '../../../Components/KeyIndices/KeyIndices';
import AppStores from '../../../Components/Dialogs/AppStores';
import GlobalWarningDialog from '../../../Utils/Layout/GlobalWarningDialog/GlobalWarningDialog';
import PortfolioDialog from '../../../Components/Dialogs/PortfolioDialog';
import AdminAppNavbar from '../../../Components/AdminAppNavbar/AdminAppNavbar';
import { GlobalUserContext, GlobalUserContextPayload } from '../../../Context/GlobalUserDetailContext';
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import ComplianceDocument from '../../../Components/ComplianceDocument/ComplianceDocument';
import StocksMarquee from '../../../Components/StocksMarquee/StocksMarquee';
import AppClientMarketingDialog from '../../../Components/Dialogs/AppClientMarketingDialog';
import { DashboardContext, DashboardDetailPayload } from '../../../Context/DashboardDetailContext';
import agent from '../../../Services/api';
import Axios from '../../../Services/axios';
import XMLParser from 'react-xml-parser';
import { useSnackbar } from '../../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';
import axios from 'axios';
import SsoDialog from '../../../Components/Dialogs/SsoDialog';
import CredentialsDialog from '../../../Components/Dialogs/CredentialsDialog';


export default function DashoboardHome() {


    const {
        isAdminNavbarVisible,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const {
        dashboardDetails,
    } = useContext(DashboardContext) as DashboardDetailPayload

    // CONST
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();

    const containerRef = useRef<HTMLDivElement>(null);
    const isBelow600 = useMediaQuery('(max-width : 600px) and (min-width : 0px)')

    // DIALOG FLAGS
    const [accessDialog, setFlagAccessDialog] = useState(false);
    const [flagPortfolioDialog, setFlagPortfolioDialog] = useState(false);
    const [flagAppStoreDialog, setFlagAppStoreDialog] = useState(false);
    const [flagClientMarketingDialog, setFlagClientMarketingDialog] = useState<boolean | null>(false);
    const [flagOpenKeyIndeciesDialog, setFlagOpenKeyIndeciesDialog] = useState(false);
    const [flagSSODialog, setFlagSSODialog] = useState(false);
    const [flagCredentialDialog, setFlagCredentialDialog] = useState(false);

    const [currentTab, setCurrentTab] = useState<any>("information");
    const [keyIndicesCurrentTab, setKeyIndicesCurrentTab] = useState("Indices");
    const [showAppName, setShowAppName] = useState<any>(null);
    const [radtailLinkData, setRadtailLinkData] = useState<any>([]);

    const [currentAppUrl, setCurrentAppUrl] = useState<any>("");
    const [storedBase64URL, setStoredBase64URL] = useState<any>(null);

    // DATA STORED
    const [featuredAppData, setFeaturedAppData] = useState<any[] | null>(null)
    const [categorizedApps, setCategorizedApps] = useState<any>({});
    const [tabs, setTabs] = useState<any[]>([]);
    const [multiRedirectDialogData, setMultiRedirectDialogData] = useState<any[] | null>([])

    // LOGS
    // console.log("dashboardDetails", dashboardDetails);


    // USE EFFECT -----
    useEffect(() => {
        if (!dashboardDetails || !dashboardDetails.apps) return;

        // Filter apps that are not installed and sort by appOrder
        const appNotInstalledFilterData = dashboardDetails?.apps?.filter((item) => item.flagInstalled == false).sort((a, b) => a.appOrder - b.appOrder);
        setFeaturedAppData(appNotInstalledFilterData);

        // Filter apps where flagInstalled is true
        const installedApps = dashboardDetails?.apps?.filter((item) => item.flagInstalled === true);

        // Process the apps data to categorize
        const sections = Array.from(new Set(dashboardDetails?.apps?.map((app) => app.appSection)));

        const newTabs = sections.map((section) => ({
            label: section,
            value: section.toLowerCase().replace(/\s+/g, "-"),
        }));

        const categorizedData = sections.reduce((acc: any, section: any) => {
            acc[section.toLowerCase().replace(/\s+/g, "-")] = installedApps.filter((app: any) => app.appSection === section).sort((a: any, b: any) => a.appOrder - b.appOrder)
                .map((app: any) => ({
                    appId: app.appId,
                    appName: app.appName,
                    image: `${baseURL}${app.image}`,
                    redirectUrls: app.redirectUrl.map((urlObj: any) => ({
                        icon: urlObj.icon,
                        title: urlObj.title,
                        url: urlObj.url,
                    })),
                    flagAccess: app.flagAccess,
                    status: app.status
                }));
            return acc;
        }, {});

        setTabs(newTabs);
        setCategorizedApps(categorizedData);
    }, [dashboardDetails]);

    useEffect(() => {
        // Check if the script already exists
        const scriptId = "tradingview-widget-script";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
            script.async = true;

            script.innerHTML = JSON.stringify({
                colorTheme: "dark",
                dateRange: "12M",
                showChart: false,
                locale: "en",
                largeChartUrl: "",
                isTransparent: true,
                showSymbolLogo: true,
                showFloatingTooltip: false,
                width: "100%",
                height: "100%",
                scaleFontColor: "#ffffff", // Axis and text colors
                symbolActiveColor: "rgba(41, 98, 255, 0.5)", // Highlight color
                plotLineColorGrowing: "#00ff00", // Growing line (green)
                tabs: [
                    {
                        title: "Indices",
                        symbols: [
                            { s: "FOREXCOM:SPXUSD", d: "S&P 500 Index" },
                            { s: "FOREXCOM:NSXUSD", d: "Nasdaq 100" },
                            { s: "FOREXCOM:DJI", d: "Dow Jones Industrial Average" },
                            { s: "INDEX:NKY", d: "Nikkei 225" },
                            { s: "INDEX:DEU40", d: "DAX Index" },
                            { s: "FOREXCOM:UKXGBP", d: "FTSE 100" },
                        ],
                        originalTitle: "Indices",
                    },
                    {
                        title: "Futures",
                        symbols: [
                            { s: "CME_MINI:ES1!", d: "S&P 500" },
                            { s: "CME:6E1!", d: "Euro" },
                            { s: "COMEX:GC1!", d: "Gold" },
                            { s: "NYMEX:CL1!", d: "WTI Crude Oil" },
                            { s: "NYMEX:NG1!", d: "Gas" },
                            { s: "CBOT:ZC1!", d: "Corn" },
                        ],
                        originalTitle: "Futures",
                    },
                    {
                        title: "Bonds",
                        symbols: [
                            { s: "CBOT:ZB1!", d: "T-Bond" },
                            { s: "CBOT:UB1!", d: "Ultra T-Bond" },
                            { s: "EUREX:FGBL1!", d: "Euro Bund" },
                            { s: "EUREX:FBTP1!", d: "Euro BTP" },
                            { s: "EUREX:FGBM1!", d: "Euro BOBL" },
                        ],
                        originalTitle: "Bonds",
                    },
                    {
                        title: "Forex",
                        symbols: [
                            { s: "FX:EURUSD", d: "EUR to USD" },
                            { s: "FX:GBPUSD", d: "GBP to USD" },
                            { s: "FX:USDJPY", d: "USD to JPY" },
                            { s: "FX:USDCHF", d: "USD to CHF" },
                            { s: "FX:AUDUSD", d: "AUD to USD" },
                            { s: "FX:USDCAD", d: "USD to CAD" },
                        ],
                        originalTitle: "Forex",
                    },
                ],
            });

            if (containerRef.current) {
                containerRef.current.appendChild(script);
            }
        }
    }, []);
    // USE EFFECT -----

    // HANDLE FUNCTION
    const handleTabChange = (newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleKeyIndicesTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setKeyIndicesCurrentTab(newValue);
    };

    console.log("storedBase64URL", storedBase64URL);


    const getRedtailSSO = async (baseURL: any = "dashboard") => {
        try {
            const response = await fetch(`https://api2.redtailtechnology.com/crm/v1/rest/sso?ep=${baseURL}`, {
                method: 'GET',
                headers: {
                    'Authorization': `userkeyauth ${storedBase64URL}`,
                    'Accept': 'application/xml',
                }
            });

            const data = await response.text();
            // console.log("data", data);

            if (data) {
                var xml = new XMLParser().parseFromString(data);
                let returnURL = xml.children?.find((child: any) => child.name === "ReturnURL")?.value;
                returnURL = returnURL.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                if (returnURL && /^https?:\/\/[^ "]+$/.test(returnURL)) {
                    window.open(returnURL, '_blank');
                    setFlagSSODialog(false)
                    setStoredBase64URL(null)
                }
                else {
                    console.log("xml.children", xml.children);
                    const errorMessage = xml.children?.find((child: any) => child.name === "Message")?.value || "Something went wrong!";
                    showSnackbar(errorMessage, "error");
                    setStoredBase64URL(null)
                    // throw new Error("Something Went Wrong!");
                }
            }
        } catch (err) {
            console.error("Something went wrong", err);
            console.log("catch errors", err);
            const errorMessage = err instanceof Error ? err.message : "Something went wrong!";
            showSnackbar(errorMessage, "error");

        }
    };


    const handleApps = async (event: React.MouseEvent<HTMLElement>, ele: any) => {
        debugger

        event.preventDefault();
        setShowAppName(ele);

        if (!ele.flagAccess) {
            setFlagAccessDialog(true);
            setShowAppName(ele);
            return;
        }

        const redirectUrls = ele.redirectUrls || [];
        const filterRaidtailSsoId = Number(ele.appId)

        if (filterRaidtailSsoId == 4) {
            try {
                const response: any = await agent.getRedTailSsoAPI.get()
                if (response?.status) {
                    console.log('SSO API call successful:', response);
                    const base64 = response?.data?.userKey;
                    setFlagSSODialog(true)
                    setStoredBase64URL(base64)
                    setRadtailLinkData(redirectUrls)
                    // await getRedtailSSO(base64)
                    return;
                }
                else {
                    const showError = response?.error.message ? response?.error.message : "Something Went Wrong!"
                    showSnackbar(showError, "error");
                    console.error('SSO API response status indicates failure:', response);
                }
            }
            catch (error) {
                console.error('Error hitting API:', error);
                showSnackbar("Something Went Wrong!", "error");
            }
        }

        if (redirectUrls.length > 1) {
            setMultiRedirectDialogData(redirectUrls);
            setFlagClientMarketingDialog(true);
            return;
        }
        if (redirectUrls.length === 1) {
            window.open(redirectUrls[0]?.url, "_blank");
            return;
        }
        debugger
        // Handle specific app names
        const appName = ele.appName?.trim().toLowerCase();
        if (appName === "revenue app") {
            navigate("/dashboard/revenue");
            return;
        }
        if (appName === "401(k) plan fee comparison") {
            navigate("/dashboard/plan401k");
            return;
        }
    };
    // HANDLE FUNCTION

    return (
        <>
            {flagOpenKeyIndeciesDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="lg"
                    dialogHead="Key Indices"
                    open={flagOpenKeyIndeciesDialog}
                    view={
                        <KeyIndecesDialog
                            keyIndicesTabs={keyIndicesTabs}
                            keyIndicesCurrentTab={keyIndicesCurrentTab}
                            handleKeyIndicesTabChange={handleKeyIndicesTabChange}
                            keyIndicesData={keyIndicesData}
                            flagOpenKeyIndeciesDialog={flagOpenKeyIndeciesDialog}
                        />
                    }
                    onClose={() => { setFlagOpenKeyIndeciesDialog(false) }}
                />
            }

            {flagClientMarketingDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="md"
                    dialogHead={showAppName.appName ? showAppName.appName : ""}
                    open={flagClientMarketingDialog}
                    view={
                        <AppClientMarketingDialog
                            setFlagClientMarketingDialog={setFlagClientMarketingDialog}
                            multiRedirectDialogData={multiRedirectDialogData}
                        />
                    }
                    onClose={() => { setFlagClientMarketingDialog(false) }}
                />
            }


            {flagSSODialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="md"
                    dialogHead={showAppName.appName ? showAppName.appName : ""}
                    open={flagSSODialog}
                    view={
                        <SsoDialog
                            setFlagSSODialog={setFlagSSODialog}
                            currentAppUrl={currentAppUrl}
                            setCurrentAppUrl={setCurrentAppUrl}
                            radtailLinkData={radtailLinkData}
                            getRedtailSSO={getRedtailSSO}
                        />
                    }
                    child={
                        <Grid container sx={{ gap: "5px" }}>
                            <Grid display="flex" alignItems={"center"} marginRight={"10px"}>
                                <Grid container display="flex" alignItems={"center"}>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14}`}
                                    >
                                        Status
                                    </Typography>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.globalTextV14}`}
                                        sx={{ color: showAppName.status == "Active" ? "green" : "White" }}
                                    >
                                        :&nbsp;{showAppName.status}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: "auto" }}>
                                <GlobalButton
                                    value='Update Redtail Credentials'
                                    size={"medium"}
                                    className={styles.updateCredButton}
                                    onClick={() => {
                                        setFlagCredentialDialog(true);
                                        setFlagSSODialog(false)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                    onClose={() => { setFlagSSODialog(false) }}
                />
            }



            {flagCredentialDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="sm"
                    dialogHead="Updated Redtail Credentials"
                    open={flagCredentialDialog}
                    view={
                        <CredentialsDialog
                            flagCredentialDialog={flagCredentialDialog}
                            setFlagCredentialDialog={setFlagCredentialDialog}
                            getRedtailSSO={getRedtailSSO}
                            setCurrentAppUrl={setCurrentAppUrl}
                        />
                    }
                    onClose={() => { setFlagCredentialDialog(false) }}
                />
            }


            {flagAppStoreDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="lg"
                    dialogHead="App Store"
                    open={flagAppStoreDialog}
                    view={
                        <AppStores
                            accessDialog={accessDialog}
                            setFlagAccessDialog={setFlagAccessDialog}
                            setShowAppName={setShowAppName}
                        />
                    }
                    onClose={() => { setFlagAppStoreDialog(false) }}
                />
            }

            {accessDialog && (
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="sm"
                    dialogHead={showAppName.appName ? showAppName.appName : ""}
                    open={accessDialog}
                    view={
                        <GlobalWarningDialog
                            popupDescription={"This is a restricted app. You would need Permission to Install this App."}
                            primaryButtonText={"Request For Access"}
                            secondaryButtonText={"Close"}
                            primaryButtonWidth="175px"
                            secondaryButtonWidth="175px"
                            handleClose={() => { setFlagAccessDialog(false) }}
                        />
                    }
                    onClose={() => { setFlagAccessDialog(false) }}
                />
            )}

            {flagPortfolioDialog && (
                <GlobalDialog
                    open={flagPortfolioDialog}
                    flagHeadSection={true}
                    maxWidth="md"
                    dialogHead="Portfolio"
                    view={
                        <PortfolioDialog />
                    }
                    onClose={() => { setFlagPortfolioDialog(false) }}
                />
            )}

            <Grid container>
                {/* <AppNavbar navbarHeadingName={"Dashboard"} /> */}
                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                    <Grid container >
                        <Grid size={{ xs: 12 }} className={`${styles.dashboardMarqueMainItem}`}>
                            <StocksMarquee />
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ justifyContent: 'space-between', }}>
                                <Grid size={{ xl: 8.45, lg: 8, md: 12, sm: 12, xs: 12 }} className={`${styles.dashboardLeftSection}`} >
                                    <Grid container>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsFeatureSectionItem} `}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} className={`${styles.dsCardHeadItem}`}>
                                                    <Typography component={'span'} className={`${isBelow600 ? globalStyles.globalTextV18 : globalStyles.globalHeadV2} ${globalStyles.gblCWhite}`}>
                                                        My Apps
                                                    </Typography>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} className={`${globalStyles.globalTabMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTabs
                                                                tabs={tabs}
                                                                currentTab={currentTab}
                                                                onChange={(event: React.SyntheticEvent, newValue: string) => handleTabChange(newValue)}
                                                                sxTabStyle={{
                                                                    textTransform: 'none',
                                                                    color: '#7E7E7E',
                                                                    marginRight: '20px',
                                                                    fontWeight: 'normal',
                                                                    fontSize: '1rem',
                                                                    '&.Mui-selected': {
                                                                        color: '#ffffff',
                                                                        fontWeight: 'bold',
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Grid container>
                                                                <Grid size={{ xs: 12 }}>
                                                                    {/* {tabsConfig &&
                                                                
                                                            } */}
                                                                </Grid>
                                                                <Grid size={{ xs: 12 }}></Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container className={`${styles.featureCardContainer}`}>
                                                        {(categorizedApps && categorizedApps[currentTab])?.map((ele: any, index: number) => (
                                                            <Grid
                                                                key={index}
                                                                className={`${styles.featureCardAppsItem}`}
                                                                sx={{ padding: "10px 0px" }}
                                                            >
                                                                <FeaturedCards
                                                                    featureName={ele.appName}
                                                                    featureImg={ele.image}
                                                                    featureStyling={{
                                                                        fontSize: "15px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                    onClick={(event: any) => {
                                                                        handleApps(event, ele)
                                                                        // console.log("ele", ele);
                                                                        // setShowAppName(ele)
                                                                        // if (!ele.flagAccess) {
                                                                        //     setFlagAccessDialog(true)
                                                                        //     return;
                                                                        // }
                                                                        // if (ele.path) {
                                                                        //     window.open(ele.path, "_blank");
                                                                        // }
                                                                        // else if (ele.flagAccess) {
                                                                        //     setFlagClientMarketingDialog(true);
                                                                        // }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsPorfolioCards} ${styles.dsGlobalCardStyles}`}>
                                            <Grid container className={`${styles.dsPortfolioSectionContainer}`} >
                                                <Grid size={{ xs: 12 }} className={`${styles.dsPortfolioSectionItem}`}>
                                                    <Grid container sx={{ justifyContent: "space-between", gap: "10px", height: '100%' }}>
                                                        <Grid size={{ xs: "grow" }}>
                                                            <Typography
                                                                component='span'
                                                                className={`${isBelow600 ? globalStyles.globalTextV18 : globalStyles.globalHeadV2} ${globalStyles.gblCWhite}`}
                                                            >
                                                                Portfolio
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: "auto" }} sx={{ textAlign: 'end' }}>
                                                            <GlobalButton
                                                                value='Add Portfolio'
                                                                className={`${globalStyles.globleButtonStyleV1}`}
                                                                onClick={() => { setFlagPortfolioDialog(true) }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} className={`${styles.dsPortfolioSectionContentItem}`}>
                                                    <Typography component={'span'} className={`${globalStyles.globalHeadV1}`}>
                                                        No portfolio has been created yet.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: "grow" }} className={`${styles.dsCardHeadItem}`}>
                                                            <Typography component={'span'} className={`${isBelow600 ? globalStyles.globalTextV18 : globalStyles.globalHeadV2} ${globalStyles.gblCWhite}`}>
                                                                Featured Apps
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: "auto" }} sx={{ textAlign: 'end' }}>
                                                            <GlobalButton
                                                                value='View App Store'
                                                                className={`${globalStyles.globleButtonStyleV1}`}
                                                                onClick={() => { setFlagAppStoreDialog(true) }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container className={`${styles.featureCardContainer}`}>
                                                        {featuredAppData && featuredAppData?.map((ele: any, index: number) => {
                                                            const image = baseURL + ele.image;
                                                            return (
                                                                <Grid
                                                                    key={index}
                                                                    className={`${styles.featureCardAppsItem}`}
                                                                    sx={{ padding: "10px 0px" }}
                                                                >
                                                                    <Grid container>
                                                                        <FeaturedCards
                                                                            featureName={ele.appName}
                                                                            featureImg={image || ""}
                                                                            featureStyling={{
                                                                                fontSize: "15px",
                                                                                fontWeight: "500",
                                                                            }}
                                                                            onClick={() => {
                                                                                setShowAppName(ele.appName);
                                                                                if (!ele.flagAccess) {
                                                                                    setFlagAccessDialog(true);
                                                                                    return;
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xl: 3.45, lg: 3.8, md: 12, sm: 12, xs: 12 }} className={`${styles.dashboardRightSection}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} className={`${styles.lifebrainItem} ${styles.dsFeatureSectionItem} `}>
                                            <img
                                                src={lifebrain}
                                                alt=""
                                                style={{ width: "100%", height: '100%' }}
                                            />
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 6 }}>
                                                            <Typography component={'span'} className={`${isBelow600 ? globalStyles.globalTextV18 : globalStyles.globalHeadV2} ${globalStyles.gblCWhite}`}>
                                                                Key Indices
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 6 }} sx={{ textAlign: 'end' }}>
                                                            <GlobalButton
                                                                value='Full View'
                                                                className={`${globalStyles.globleButtonStyleV1}`}
                                                                onClick={() => {
                                                                    setFlagOpenKeyIndeciesDialog(true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} className={`${styles.keyIndicesValueitem}`}>
                                                    <Grid container sx={{ height: "100%" }}>
                                                        <div className="tradingview-widget-container" ref={containerRef}>
                                                            <div className="tradingview-widget-container__widget">
                                                            </div>
                                                            <div className="tradingview-widget-copyright" style={{ margin: '5px 0px 0px' }}>
                                                                <a
                                                                    href="https://www.tradingview.com/"
                                                                    rel="noopener nofollow"
                                                                    target="_blank"
                                                                >
                                                                    <Typography component={'span'} className={`${globalStyles.gblC00B7FF} ${globalStyles.globalTextV16}`}>
                                                                        Market Data&nbsp;
                                                                    </Typography>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                                        by Trading View
                                                                    </Typography>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                {/****************
                                                 <Grid size={{ xs: 12 }} className={`${globalStyles.globalTabMainItem}`}>
                                                    <GlobalTabs
                                                        tabs={keyIndicesTabs}
                                                        currentTab={keyIndicesCurrentTab}
                                                        onChange={(event: React.SyntheticEvent, newValue: string) => handleKeyIndicesTabChange(event, newValue)}
                                                        sxTabStyle={{
                                                            textTransform: 'none',
                                                            color: '#7E7E7E',
                                                            marginRight: '10px',
                                                            fontWeight: 'normal',
                                                            fontSize: '15px',
                                                            '&.Mui-selected': {
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <KeyIndices
                                                    keyIndicesData={keyIndicesData}
                                                    flagVisibleDivider={true}
                                                />

                                                <Grid size={{ xs: 12 }} sx={{ textAlign: 'center', margin: "15px 0px 0px" }}>
                                                    <Typography component={'span'} className={`${globalStyles.gblC00B7FF} ${globalStyles.globalTextV16}`}>
                                                        Market Data
                                                    </Typography>
                                                    &nbsp;
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        by Trading View
                                                    </Typography>
                                                </Grid> 
                                                ****************/}
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles} `}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV18} ${globalStyles.gblCWhite}`}>
                                                Schwab Advisor Center coming soon
                                            </Typography>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles} `}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV18} ${globalStyles.gblCWhite}`}>
                                                Your Acrylic Team
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ textAlign: 'center', margin: '10px 0px' }}>
                            <img
                                src={varifiedLogo}
                                alt=""
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ComplianceDocument />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

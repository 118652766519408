import React from 'react'
import styles from "../../Styles/ComponentStyles/GlobalComponentStyles/AppClientMarketingDialog.module.scss"

import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';


const AppClientMarketingDialog = (props: any) => {

    const { multiRedirectDialogData } = props

    const clientMarketingDetails = [
        {
            itemName: "Calendar",
            itemRedireactLink: "https://crm.redtailtechnology.com/calendar"
        },
        {
            itemName: "Contact",
            itemRedireactLink: "https://crm.redtailtechnology.com/contacts"
        },
        {
            itemName: "Reports",
            itemRedireactLink: "https://crm.redtailtechnology.com/reports"
        },
        {
            itemName: "Dashboard",
            itemRedireactLink: "https://crm.redtailtechnology.com/dashboard/overview"
        },
    ]

    const handleClick = (link: string) => {
        if (link) {
            window.open(link, "_blank");
        }
    };

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>

                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px" }}>
                            <Typography component={'span'}>
                                <span style={{ color: "white" }}> Status</span>&nbsp;&nbsp;
                                <Typography
                                    component={'span'}
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        color: "#24c99a",
                                        borderRadius: "5px",
                                        textAlign: "center"
                                    }}>Active</Typography>
                            </Typography>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ justifyContent: "space-between", rowGap: '10px' }}>
                                {multiRedirectDialogData && multiRedirectDialogData.map((ele: any) => {
                                    return (
                                        <>
                                            <Grid size={{ xs: 5.8 }} className={`${styles.clientItemMainItem}`}>
                                                <Grid container
                                                    sx={{ alignItems: "center", gap: "10px" }}
                                                    onClick={() => handleClick(ele.url)}
                                                >
                                                    <ArrowCircleRightIcon style={{ color: "white" }} />
                                                    <Typography component={'span'} className={`${styles.clientItemText}`}>
                                                        {ele.title}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px 15px" }}>
                            <Grid container sx={{ justifyContent: "end", gap: "10px" }}>
                                <GlobalButton
                                    value={"Uninstall"}
                                    size='medium'
                                    className={`${styles.unintallBtn}`}
                                />

                                <GlobalButton
                                    value={"Close"}
                                    size='medium'
                                    className={`${styles.closeBtn}`}
                                    onClick={() => { props.setFlagClientMarketingDialog(false) }}
                                />
                            </Grid>
                        </Grid>



                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default AppClientMarketingDialog